/** context for states */
import { State } from './state.js';

export const STEP_1 = 1;
export const STEP_2 = 2;
export const STEP_3 = 3;
export const STEP_4 = 4;
export const STEP_5 = 5;
export const STEP_6 = 6;
export const STEP_7 = 7;

function initializeStates(
  setDietaryNeedsView,
  setFoodPreferencesView,
  setStorePlanView,
  setMealPlanView,
  setRegistrationView,
  setSubscriptionView,
  setSubscriptionSubmitView,
  setSendoffView,
  setCompleted,
) {
  const dietaryNeedsState = new State(STEP_1, setDietaryNeedsView);
  const foodPreferencesState = new State(STEP_2, setFoodPreferencesView);
  const storePlanState = new State(STEP_3, setStorePlanView);
  const mealPlanState = new State(STEP_4, setMealPlanView);
  const registrationState = new State(STEP_5, setRegistrationView);
  const subscriptionState = new State(STEP_6, setSubscriptionView);
  const subscriptionSubmitState = new State(STEP_7, setSubscriptionSubmitView, setCompleted);

  // wire state machine
  dietaryNeedsState.nextState = foodPreferencesState;
  foodPreferencesState.previousState = dietaryNeedsState;
  foodPreferencesState.nextState = storePlanState;
  storePlanState.previousState = foodPreferencesState;
  storePlanState.nextState = mealPlanState;
  mealPlanState.previousState = storePlanState;
  mealPlanState.nextState = registrationState;
  registrationState.previousState = mealPlanState;
  registrationState.nextState = subscriptionState;
  subscriptionState.previousState = subscriptionState;
  subscriptionState.nextState = subscriptionSubmitState;
  subscriptionSubmitState.previousState = subscriptionState;

  return dietaryNeedsState;
}

/**
 * state machine for onboarding screen sequencing
 */
export class StateMachine {
  constructor({
    setDietaryNeedsView,
    setFoodPreferencesView,
    setStorePlanView,
    setMealPlanView,
    setRegistrationView,
    setSubscriptionView,
    setSubscriptionSubmitView,
    setSendoffView,
    setCompleted = null,
  }) {
    this.setDietaryNeedsView = setDietaryNeedsView;
    this.setFoodPreferencesView = setFoodPreferencesView;
    this.setStorePlanView = setStorePlanView;
    this.setMealPlanView = setMealPlanView;
    this.setRegistrationView = setRegistrationView;
    this.setSubscriptionView = setSubscriptionView;
    this.setSubscriptionSubmitView = setSubscriptionSubmitView;
    this.setSendoffView = setSendoffView;
    this.setCompleted = setCompleted;

    this.setInitialState();
  }

  setInitialState() {
    this._currentState = initializeStates(
      this.setDietaryNeedsView,
      this.setFoodPreferencesView,
      this.setStorePlanView,
      this.setMealPlanView,
      this.setRegistrationView,
      this.setSubscriptionView,
      this.setSubscriptionSubmitView,
      this.setSendoffView,
      this.setCompleted,
    );
  }

  get stepNumber() {
    return this._currentState.stepNumber;
  }

  start() {
    this.setInitialState();
    this._currentState.enter();
  }

  next() {
    const nextState = this._currentState.next();
    if (nextState) {
      this._currentState = nextState;
      this._currentState.enter();
    }
  }

  previous() {
    const prevState = this._currentState.previous();
    if (prevState) {
      this._currentState = prevState;
      this._currentState.enter();
    }
  }

  last() {
    let nextState = this._currentState.next();
    while (nextState) {
      this._currentState = nextState;
      nextState = this._currentState.next();
    }
    this._currentState.enter();
  }
}
