import { html, css, LitElement } from 'lit-element';
import { sharedStyles } from '../../../theme/shared-styles.js';

/**
 * dot-bar onboarding progress indicator
 */
class DdStepProgress extends LitElement {
  static get properties() {
    return {
      first: { type: Number },
      last: { type: Number },
      current: { type: Number },
      labels: { type: Array },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        .bar {
          height: 4px;
          border-radius: 100px;
          flex-grow: 1;
          border-radius: 100px;
        }

        .step {
          display: flex;
          position: relative;
        }

        .dot {
          box-sizing: border-box;
          height: 16px;
          width: 16px;
          border-radius: 50%;
          display: inline-block;
          flex-grow: 0;
          flex-shrink: 0;
          margin: 4px;
        }

        .label {
          position: absolute;
          margin-top: 20px;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
          font-size: 12px;
          color: #4b4b4b;
        }

        .active {
          background: var(--mdc-theme-primary-dark);
        }

        .inactive {
          background: var(--dd-theme-main-light);
        }

        .dot-current {
          background: var(--dd-theme-main-light);
          border: 4px solid var(--mdc-theme-primary-dark);
        }
      `,
    ];
  }

  constructor() {
    super();

    this.first = 0;
    this.last = 0;
    this.current = 0;
  }

  render() {
    const hasSteps = this.last > this.first;
    const visibilityClass =
      hasSteps && this.current >= this.first && this.current <= this.last
        ? 'dd-visible'
        : 'dd-invisible';

    return html`
      <div id="container" class="${visibilityClass} dd-flex-container">
        ${this.getDotTemplate_(this.first, this.labels?.[0])} ${this.getBarDotTemplates_()}
      </div>
    `;
  }

  getDotTemplate_(i, label) {
    return html`
      <div class="step">
        <div class="dot ${this.getDotClass_(i)}"></div>
        <span class="label">${label}</span>
      </div>
    `;
  }

  getBarTemplate_(i) {
    return html`
      <div class="bar ${this.getBarClass_(i)}"></div>
    `;
  }

  getBarDotTemplates_() {
    const barDots = [];
    if (this.last > this.first) {
      for (let i = this.first + 1; i <= this.last; i += 1) {
        barDots.push(this.getBarTemplate_(i));
        barDots.push(this.getDotTemplate_(i, this.labels?.[i - 1]));
      }
    }

    return barDots;
  }

  getDotClass_(i) {
    if (i < this.current) {
      return 'active';
    }
    if (i === this.current) {
      return 'dot-current';
    }
    return 'inactive';
  }

  getBarClass_(i) {
    if (i <= this.current) {
      return 'active';
    }
    return 'inactive';
  }
}

customElements.define('dd-step-progress', DdStepProgress);
