/**
 * API actions for diet-plan-options resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *      https://docs.google.com/spreadsheets/d/1MmffvNk7OJFhEYA2ZSUOVZ6OT_um1848_o-EpzCv1MA/edit?usp=sharing
 */
import { axios, getApiUrl, API_REQUEST, completeRequest, NOK_RESPONSE_HINT } from './api-base.js';
import { saveDietPlanOptions } from '../diet-plan-options.js';

// REST resources
const DEFAULT_DIET_PLAN_OPTIONS_RESOURCE = 'default-diet-plan-options';

/**
 * get diet plan options, including Heart Healthy status
 */
export function requestDefaultDietPlanOptions() {
  return {
    type: API_REQUEST,
    payload(dispatch) {
      const url = getApiUrl(DEFAULT_DIET_PLAN_OPTIONS_RESOURCE);

      return axios
        .get(url)
        .then(response => {
          dispatch([saveDietPlanOptions(response.data)]);
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}
