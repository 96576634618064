import { html, css } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-formfield';
import '@material/mwc-radio';
import '@material/mwc-icon';
import isEqual from 'lodash-es/isEqual.js';
import { PageViewElement } from '../../page-view-element.js';
import { StateMachine, STEP_1, STEP_7 } from './state-machine.js';
import '../dd-dietary-needs.js';
import '../dd-food-limits.js';
import '../dd-store-select.js';
import '../dd-meal-size-select.js';
import '../dd-registration.js';
import '../dd-subscription-select.js';
import '../dd-subscription-submit.js';
import '../dd-mini-menu.js';
import './dd-step-progress.js';
import '../../dd-api-progress.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-onboarding.scss';
import { ASYNC_PAUSE } from '../../../utilities/constants.js';
import { register } from '../../../actions/api/auth-api.js';
import { store } from '../../../state/store.js';
// const REGISTER_SUBMIT = 'register-submit';
const ONBOARDING_COMPLETED_EVENT = 'onboarding-completed';

const DIETARY_NEEDS_VIEW = 'dietary-needs';
const FOOD_PREFS_VIEW = 'food-prefs';
const STORE_PLAN_VIEW = 'store-plan';
const MEAL_PLAN_VIEW = 'meal-plan';
const REGISTRATION_VIEW = 'registration';
const SUBSCRIPTION_VIEW = 'subscription';
const SUBSCRIPTION_SUBMIT_VIEW = 'subscription-submit';
const SENDOFF_VIEW = 'sendoff';

class DdOnboarding extends PageViewElement {
  static get properties() {
    return {
      dietPlanOptions: { type: Object },
      chargebeeInstance: { type: Object },
      store: { type: String },
      weekMenu: { type: Object },
      planId: { type: String },
      _internalDietPlanOptions: { type: Object },
      _stepNumber: { type: Number }, // internal render trigger on step progress
    };
  }

  set dietPlanOptions(value) {
    // freeze state once onboarding started
    if (this._stateMachine.stepNumber === STEP_1) {
      const oldValue = this._dietPlanOptions;
      this._dietPlanOptions = value;
      this._internalDietPlanOptions = value;
      this.requestUpdate('dietPlanOptions', oldValue);
    }
  }

  set chargebeeInstance(value) {
    // freeze state once onboarding started
    const oldValue = this._chargebeeInstance;
    this._chargebeeInstance = value;
    this.requestUpdate('chargebeeInstance', oldValue);
  }

  set store(value) {
    const oldValue = this._store;
    this._store = value;
    this._internalStore = value;
    this.requestUpdate('store', oldValue);
  }

  set planId(value) {
    const oldValue = this._planId;
    this._planId = value;
    this.requestUpdate('planId', oldValue);
  }

  set userId(value) {
    const oldValue = this._userId;
    this._userId = value;
    this.requestUpdate('userId', oldValue);
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        @media only screen and (max-width: 640px) {
          .hide-mobile {
            display: none !important;
          }
        }
      `,
    ];
  }

  constructor() {
    super();

    this._dietPlanOptions = {};
    this._internalDietPlanOptions = {};
    this._chargebeeInstance = {};
    this._store = '';
    this._planId = '';
    this._userId = '';
    this.weekMenu = { metadata: {} };
    this._stepNumber = STEP_1 - 1;

    this._stateMachine = new StateMachine({
      setDietaryNeedsView: this.setDietaryNeedsView_.bind(this),
      setFoodPreferencesView: this.setFoodPreferencesView_.bind(this),
      setStorePlanView: this.setStorePlanView_.bind(this),
      setMealPlanView: this.setMealPlanView_.bind(this),
      setRegistrationView: this.setRegistrationView_.bind(this),
      setSubscriptionView: this.setSubscriptionView_.bind(this),
      setSubscriptionSubmitView: this.setSubscriptionSubmitView_.bind(this),
      setSendoffView: this.setSendoffView_.bind(this),
      setCompleted: this.setCompleted_.bind(this),
    });
  }

  firstUpdated() {
    super.firstUpdated();
    this._stateMachine.start();
  }

  updated(changedProperties) {
    super.updated(changedProperties);

    // monkey patch
    const navButtons = this.shadowRoot.querySelectorAll('mwc-button.nav-button');
    navButtons.forEach(navButton => {
      const buttonNode = navButton.shadowRoot.querySelector('button');
      const stylePatch = document.createElement('style');
      stylePatch.innerHTML = '.mdc-button--outlined { height: 48px !important; }';
      buttonNode.appendChild(stylePatch);
    });
  }

  stateChanged(state) {
    const apiSnackbar = this.shadowRoot.querySelector('dd-api-snackbar');
    if (apiSnackbar) {
      apiSnackbar.message = state.api.message;
      apiSnackbar.isFetching = state.api.isFetching;
    }

    const apiProgress = this.shadowRoot.querySelector('dd-api-progress');
    if (apiProgress) {
      apiProgress.isFetching = state.api.isFetching;
    }

    const registrationButton = this.shadowRoot.querySelector(`#${REGISTRATION_VIEW}-next`);
    if (registrationButton) {
      registrationButton.disabled = state.api.isFetching;
    }
  }

  render() {
    return html`
      <div id="grid-outer" class="dd-flex-container">
        <div id="grid-middle" class="dd-fit-content">
          <div id="grid-inner">
            <dd-api-progress></dd-api-progress>
            <div class="content-container">
              <div id="progress-container" class="dd_hide hide-mobile">
                <dd-step-progress
                  .first=${STEP_1}
                  .last=${STEP_7}
                  .current=${this._stateMachine.stepNumber}
                  .labels=${[
                    'Dietary Needs',
                    'Food Preferences',
                    'Store Selection',
                    'Meal Size',
                    'Account Creation',
                    'Plan',
                    'Finish',
                    'Complete!',
                  ]}
                ></dd-step-progress>
              </div>

              <div id="${DIETARY_NEEDS_VIEW}" class="onboarding-stage dd_hide">
                <h2>Do you have any <span class="dd-h2 bolder">Dietary Needs?</span></h2>
                <p class="hint color-hint-onboarding">
                  Select all that apply. If you have none, just leave all options unchecked.
                </p>
                <dd-dietary-needs
                  include-hh
                  .dietPlanOptions=${this._internalDietPlanOptions}
                ></dd-dietary-needs>

                <p class="hint color-hint-onboarding bold margin-0">
                  Don't worry, you can change any of your preferences at any time.
                </p>
              </div>

              <div id="${FOOD_PREFS_VIEW}" class="onboarding-stage dd_hide">
                <h2 class="heading-margin">
                  What are your <span class="dd-h2 bolder">Food Preferences?</span>
                </h2>
                <p class="hint color-hint-onboarding">
                  Select the types of food you prefer and the “Up To” number of times it can appear
                  on your weekly menu. <br />
                  <span class="bold">You can choose up to 3</span> in each food type – the higher
                  the total for all foods, the more variety you will have in your menus!
                </p>

                <dd-food-limits
                  .dietPlanOptions=${this._internalDietPlanOptions}
                  @food-limit-total-changed="${this.onFoodLimitTotalChanged_}"
                ></dd-food-limits>
              </div>

              <div id="${STORE_PLAN_VIEW}" class="onboarding-stage dd_hide">
                <dd-store-select
                  .familySize=${this.weekMenu.metadata.familySize}
                  @store-selected="${this.onStoreSelected_}"
                ></dd-store-select>
              </div>
              <div id="${MEAL_PLAN_VIEW}" class="onboarding-stage dd_hide">
                <dd-meal-size-select
                  @family-size-changed="${this.onMealPlanSelected_}"
                ></dd-meal-size-select>
              </div>
              <div id="${REGISTRATION_VIEW}" class="onboarding-stage dd_hide">
                <dd-registration
                  @registration-values-changed="${this.onRegistrationValuesChanged_}"
                ></dd-registration>
              </div>
              <div id="${SUBSCRIPTION_VIEW}" class="onboarding-stage dd_hide">
                <dd-subscription-select
                  .planId=${this._planId}
                  @option-click="${this.onSubscriptionSelected_}"
                ></dd-subscription-select>
              </div>
              <div id="${SUBSCRIPTION_SUBMIT_VIEW}" class="onboarding-stage dd_hide">
                <dd-subscription-submit
                  .planId=${this._planId}
                  .userId=${this._userId}
                  .chargebeeInstance=${this._chargebeeInstance}
                  @subscription-created="${this.onSubscriptionSubmitted_}"
                ></dd-subscription-submit>
              </div>
              <div id="${SENDOFF_VIEW}" class="onboarding-stage dd_hide">
                <h2>That's it</h2>
                <h1 class="heading-margin">You're done!</h1>
                <p>
                  Please watch our quick video tour below. Also, Keep an eye out for a series of
                  quick helpful tips delivered to your inbox over the next couple of weeks.
                </p>
                <div class="dd-section-vertical-margin">
                  <iframe
                    width="100%"
                    height="315px"
                    src="https://fast.wistia.net/embed/iframe/07mo4bfkpe"
                    title="Wistia video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <p>And remember, you can change any of your preferences at any time.</p>
                <p>Now let’s get you to your first menu!</p>
              </div>
            </div>

            <div class="spacer"></div>
            <div id="${DIETARY_NEEDS_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="next"
                class="nav-button dark-filled"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${FOOD_PREFS_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="back"
                class="nav-button light-filled dd-button-margin-right"
                @click="${this.onPreviousClick_}"
              >
                <mwc-icon slot="icon">arrow_back</mwc-icon>
              </mwc-button>
              <mwc-button
                id="${FOOD_PREFS_VIEW}-next"
                outlined
                label="next"
                class="nav-button dark-filled dd-invisible"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${STORE_PLAN_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="back"
                class="nav-button light-filled dd-button-margin-right"
                @click="${this.onPreviousClick_}"
              >
                <mwc-icon slot="icon">arrow_back</mwc-icon>
              </mwc-button>
              <mwc-button
                id="${STORE_PLAN_VIEW}-next"
                outlined
                label="next"
                class="nav-button dark-filled dd-invisible"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${MEAL_PLAN_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="back"
                class="nav-button light-filled dd-button-margin-right"
                @click="${this.onPreviousClick_}"
              >
                <mwc-icon slot="icon">arrow_back</mwc-icon>
              </mwc-button>
              <mwc-button
                id="${MEAL_PLAN_VIEW}-next"
                outlined
                label="next"
                class="nav-button dark-filled dd-invisible"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${REGISTRATION_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="back"
                class="nav-button light-filled dd-button-margin-right"
                @click="${this.onPreviousClick_}"
              >
                <mwc-icon slot="icon">arrow_back</mwc-icon>
              </mwc-button>
              <mwc-button
                id="${REGISTRATION_VIEW}-next"
                outlined
                label="next"
                class="nav-button dark-filled dd-invisible"
                @click="${this.onRegisterSubmit_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div id="${SUBSCRIPTION_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                id="${SUBSCRIPTION_VIEW}-next"
                outlined
                label="next"
                class="nav-button dark-filled dd-invisible"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">arrow_forward</mwc-icon>
              </mwc-button>
            </div>
            <div
              id="${SUBSCRIPTION_SUBMIT_VIEW}-nav"
              class="dd-flex-end-container nav-container dd_hide"
            >
              <mwc-button
                outlined
                label="back"
                class="nav-button light-filled dd-button-margin-right"
                @click="${this.onPreviousClick_}"
              >
                <mwc-icon slot="icon">arrow_back</mwc-icon>
              </mwc-button>
            </div>
            <div id="${SENDOFF_VIEW}-nav" class="dd-flex-end-container nav-container dd_hide">
              <mwc-button
                outlined
                label="finish"
                class="nav-button dark-filled"
                @click="${this.onNextClick_}"
              >
                <mwc-icon slot="trailingIcon">done</mwc-icon>
              </mwc-button>
            </div>
          </div>
        </div>
      </div>
      ${this.getSpacerTemplate_(0)}
    `;
  }

  setDietaryNeedsView_() {
    this.setOnboardingStageView_(DIETARY_NEEDS_VIEW);
  }

  setFoodPreferencesView_() {
    this.updateInternalDietPlanOptions_(FOOD_PREFS_VIEW);
    this.setOnboardingStageView_(FOOD_PREFS_VIEW);

    const foodLimits = this.shadowRoot.querySelector('dd-food-limits');
    setTimeout(() => {
      foodLimits.onShow();
    }, ASYNC_PAUSE);
  }

  setStorePlanView_() {
    this.updateInternalDietPlanOptions_(STORE_PLAN_VIEW);
    this.setOnboardingStageView_(STORE_PLAN_VIEW);
    const storePlan = this.shadowRoot.querySelector('dd-store-select');
    storePlan.focus();
  }

  setMealPlanView_() {
    this.updateInternalDietPlanOptions_(MEAL_PLAN_VIEW);
    this.setOnboardingStageView_(MEAL_PLAN_VIEW);
  }

  setRegistrationView_() {
    this.setOnboardingStageView_(REGISTRATION_VIEW);
  }

  setSubscriptionView_() {
    this.setOnboardingStageView_(SUBSCRIPTION_VIEW);
  }

  setSubscriptionSubmitView_() {
    this.setOnboardingStageView_(SUBSCRIPTION_SUBMIT_VIEW);
  }

  setSendoffView_() {
    this.setOnboardingStageView_(SENDOFF_VIEW);

    this._stepNumber = this._stateMachine.stepNumber;
  }

  setCompleted_() {
    const event = new CustomEvent(ONBOARDING_COMPLETED_EVENT, {});
    this.dispatchEvent(event);
  }

  setOnboardingStageView_(id) {
    const views = this.shadowRoot.querySelectorAll('.onboarding-stage');
    views.forEach(view => {
      if (view.id === id) {
        view.classList.replace('dd_hide', 'dd_show');
      } else {
        view.classList.replace('dd_show', 'dd_hide');
      }
    });

    const navs = this.shadowRoot.querySelectorAll('.nav-container');
    navs.forEach(nav => {
      if (nav.id === `${id}-nav`) {
        nav.classList.replace('dd_hide', 'dd_show');
      } else {
        nav.classList.replace('dd_show', 'dd_hide');
      }
    });

    this.showHideProgressBar_(id);
    this.requestUpdate();
    window.scrollTo(0, 0);
  }

  showHideProgressBar_(id) {
    const progressContainer = this.shadowRoot.querySelector('#progress-container');
    if (id === SENDOFF_VIEW) {
      progressContainer.classList.replace('dd_show', 'dd_hide');
    } else {
      progressContainer.classList.replace('dd_hide', 'dd_show');
    }
  }

  setNextButtonVisibility_(view, condition) {
    const nextButton = this.shadowRoot.querySelector(`#${view}-next`);
    if (condition) {
      nextButton.classList.replace('dd-invisible', 'dd-visible');
    } else {
      nextButton.classList.replace('dd-visible', 'dd-invisible');
    }
  }

  updateInternalDietPlanOptions_(view) {
    let updaterSelector = null;

    switch (view) {
      case FOOD_PREFS_VIEW:
        updaterSelector = 'dd-dietary-needs';
        break;
      case STORE_PLAN_VIEW:
        updaterSelector = 'dd-food-limits';
        break;
      default:
        break;
    }

    if (updaterSelector) {
      const updater = this.shadowRoot.querySelector(updaterSelector);
      this._internalDietPlanOptions = updater.dietPlanOptions;
    }
  }

  isPrefsChanged_() {
    const storePlan = this.shadowRoot.querySelector('dd-store-select');
    const prefsEqual = isEqual(this._dietPlanOptions, this._internalDietPlanOptions);
    const storeEqual = this._store === storePlan.store;
    return !prefsEqual || !storeEqual;
  }

  onPreviousClick_() {
    this.scrollTop = 0;
    this._stateMachine.previous();
  }

  onNextClick_() {
    this.scrollTop = 0;
    this._stateMachine.next();
  }

  onNextSubmitClick_() {
    this.scrollTop = 0;
    this._stateMachine.next();
  }

  onLastStep_() {
    this._stateMachine.last();
  }

  onFoodLimitTotalChanged_(event) {
    this.setNextButtonVisibility_(FOOD_PREFS_VIEW, event.detail.validTotal);
  }

  onStoreSelected_(event) {
    this.setNextButtonVisibility_(STORE_PLAN_VIEW, !!event.detail.store);
  }

  onMealPlanSelected_() {
    this.setNextButtonVisibility_(MEAL_PLAN_VIEW, true);
  }

  onRegistrationValuesChanged_(event) {
    this.setNextButtonVisibility_(REGISTRATION_VIEW, event.detail.validFormValues);
  }

  onSubscriptionSelected_(event) {
    this.planId = event.detail.planId;
    this.setNextButtonVisibility_(SUBSCRIPTION_VIEW, this.planId !== '');
  }

  onSubscriptionSubmitted_() {
    this._stateMachine.next();
  }

  onRegisterSubmit_() {
    const registration = this.shadowRoot.querySelector('dd-registration');
    const registrationDto = registration.values;

    const dietaryNeeds = this.shadowRoot.querySelector('dd-dietary-needs');
    const dietaryNeedsDto = dietaryNeeds.dietPlanOptionsDto;
    const foodLimits = this.shadowRoot.querySelector('dd-food-limits');
    const foodLimitsDto = foodLimits.dietPlanOptionsDto;
    const storePlan = this.shadowRoot.querySelector('dd-store-select');
    const selectedStore = storePlan.store;
    const mealSize = this.shadowRoot.querySelector('dd-meal-size-select');
    const familySize = mealSize.getMealSize;

    if (!registrationDto) {
      return;
    }

    this.setNextButtonVisibility_(REGISTRATION_VIEW, false);

    const action = register(
      registrationDto.firstName,
      registrationDto.lastName,
      registrationDto.email,
      registrationDto.password,
      familySize,
      {
        ...dietaryNeedsDto,
        ...foodLimitsDto,
        store: selectedStore,
      },
      (userId, sponsored) => {
        this.userId = userId;

        if (sponsored) {
          this.onLastStep_();
        } else {
          this.onNextClick_();
        }
      },
    );

    store.dispatch(action);
  }
}

customElements.define('dd-onboarding', DdOnboarding);
