/**
 * API actions for the profile resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 */
import {
  axios,
  getApiUrl,
  API_REQUEST,
  startRequest,
  completeRequest,
  NOK_RESPONSE_HINT,
} from './api-base.js';
import { setProfile } from '../profile';
import { setToken } from '../token';
import { LOGIN_VIEW, navigate } from '../navigation';

// REST resources
const PROFILE_ENDPOINT = 'profile';
const EMAIL_ENDPOINT = 'profile/email';

export function getProfile() {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest());

      const url = getApiUrl(PROFILE_ENDPOINT);

      return axios
        .get(url, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          /**
           * The data will be { email, first, last }
           */
          dispatch(setProfile(response.data));
          dispatch(completeRequest());
        })
        .catch(error => {
          dispatch([completeRequest(NOK_RESPONSE_HINT), setToken('', ''), navigate(LOGIN_VIEW)]);
          console.log(error);
        });
    },
  };
}

export function requestUpdateEmail(newEmail, currentEmail, successMessage) {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest());

      const url = getApiUrl(EMAIL_ENDPOINT);

      return axios
        .put(
          url,
          {
            newEmail,
            currentEmail,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then(() => {
          dispatch(completeRequest(successMessage));
          dispatch(setToken('', ''));
          dispatch(navigate(LOGIN_VIEW));
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}

export function updateProfile(newFirstName, newLastName) {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest());

      const url = getApiUrl(PROFILE_ENDPOINT);

      axios
        .put(
          url,
          {
            first: newFirstName,
            last: newLastName,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then(() => {
          dispatch(getProfile());
          dispatch(completeRequest('Profile has been updated'));
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}
