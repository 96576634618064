export const INVALID_ID = 0;

// ref: Symfony AppBundle\Entity\Recipe
export const DISH_TYPE_UNKNOWN = 0;
export const DISH_TYPE_MAIN = 1;
export const DISH_TYPE_SIDE = 2;
export const DISH_TYPE_DESSERT = 3;

// preferences business rules
export const BASICS_VEGETARIAN_LIMIT = 2;

export const TOKEN_UNAUTHORIZED = 'TOKEN_UNAUTHORIZED';
export const ASYNC_PAUSE = 10;
export const LOAD_ANIMATION_DELAY = 3000; // pause after load to start auto animations
export const OPEN_ANIMATION_DELAY = 2000; // pause after opening card to start auto animations

export const LOCATIONS_SET_EVENT = 'locations-set';
export const PRODUCTS_SET_EVENT = 'products-set';
export const REQUEST_CANCELLED_EVENT = 'request-cancelled';
export const INVALID_TOKEN_EVENT = 'invalid-token';
export const USER_LOGGED_OUT_EVENT = 'user-logged-out';

export const MAX_TEXTFIELD_LENGTH = 255;
export const VALID_CHAR_REGEX = '[a-zA-Z0-9 ,&+-/.!@*?:;^\'"()%]{3,}';
export const VALIDATION_MESSAGE = "It's either too short or has special characters";

export const TRIGGER_MENU_GENERATION = 'trigger-menu-generation';

// build flags transferred to app (removed from webpack.common.js) for released features
export const ONBOARDING = true;
export const MOBILE_PRINT = true;
export const SL_PRINT = true;
export const AUTO_COLLAPSE = false;
export const ID_PROVIDER = true;

// app flags in Local Storage
export const APP_FLAGS = {
  CREATE_MENU_TIMESTAMP: 'createMenuTimestamp',
  DEBUG: 'debug',
  DEV_FEATURE: 'devFeature',
  FIRST_VISIT_COMPLETED: 'firstVisit',
  LAT_OVERRIDE: 'latOverride',
  LONG_OVERRIDE: 'longOverride',
  MENU_ID: 'menuId',
  MENU_REQUEST_COUNT: 'menuRequestCount',
  NOTIFIED_BELOW_LIMIT: 'notifiedBelowLimit',
  NOTIFIED_NO_STORES: 'notifiedNoStores',
  NOTIFIED_NOT_MAXIMIZED: 'notifiedNotMaximized',
  ONBOARDING_COMPLETED: 'onboardingCompleted',
  SHOW_ONBOARDING_VIDEO: 'showOnboardingVideo',
  PREVIOUS_WEEK: 'previousWeek',
  SELECTED_LOCATION: 'selectedLocation',
  SELECTED_STATE: 'selectedState',
  SENT_TO_SERVER: 'sentToServer',
  TOUCH_STATUS_OVERRIDE: 'touchStatusOverride',
  VISITED_CLASSIC: 'visitedClassic',
};
Object.freeze(APP_FLAGS);

export const TOUCH_STATUS = {
  NONE: 0,
  DISABLED: 1,
  ENABLED: 2,
};
Object.freeze(TOUCH_STATUS);

export const STATUS_LEVEL = {
  OK: 0,
  WARNING: 1,
  DANGER: 2,
};
Object.freeze(STATUS_LEVEL);

export const OAUTH_SERVICE = {
  NONE: 0,
  PEAPOD: 1,
  GOOGLE: 2,
  KROGER: 3,
};
Object.freeze(OAUTH_SERVICE);

// distinguishes uninitialized state for boolean account statuses
export const ACCOUNT_STATE = {
  UNINITIALIZED: 0,
  UNAUTHORIZED: 1,
  AUTHORIZED: 2,
};
Object.freeze(ACCOUNT_STATE);

export const ANALYTICS = {
  NAVIGATION: 'navigation',
  PREFS: 'prefs',
  MEAL_PLANNING: 'meal-planning',
  SHOPPING: 'shopping',
  PERSONAL_RECIPES: 'personal-recipes',
  ACCOUNT: 'account',
};

// ref: AppBundle\Services\UserManagement\SubscriptionManagementInterface::SUBSCRIPTION_STATUS_
export const SUBSCRIPTION_STATUS = {
  NONE: 'none',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  FREE: 'free',
};
Object.freeze(SUBSCRIPTION_STATUS);

// ref: Symfony \AppBundle\Entity\User
export const FAMILY_SIZE = {
  full: {
    value: 1, // toggle on
    description: '4 to 6',
  },
  half: {
    value: 2, // toggle off
    description: '2 to 3',
  },
};
Object.freeze(FAMILY_SIZE);

// ref: Symfony AppBundle\Services\Fulfillment\FulfillmentAccountState
export const FULFILLMENT_ACCOUNT_STATE = {
  NOT_AVAILABLE: 0,
  NOT_CONNECTED: 1,
  NOT_IN_SUPPORTED_ZONE: 2,
  IN_SUPPORTED_ZONE: 3,
};
Object.freeze(FULFILLMENT_ACCOUNT_STATE);

// feature switches from server
export const FEATURES = {
  PARTIAL_QUANTITIES: 'partial-quantities',
  DEBUG: 'debug',
  PEAPOD_FULFILLMENT_CONNECT: 'fulfillment-connect',
  KROGER_FULFILLMENT: 'kroger-fulfillment',
  PEAPOD_FULFILLMENT: 'peapod-fulfillment',
  LOCATE_STORE: 'locate-store',
  USER_SAVINGS: 'user-savings',
};
Object.freeze(FEATURES);

export const DISH_TYPE = {
  MAIN: 'main',
  FIRST_SIDE: 'first-side',
  SECOND_SIDE: 'second-side',
};
Object.freeze(DISH_TYPE);

// ref: Symfony \AppBundle\Entity\Recipe
export const PROTEIN_CATEGORY = {
  NONE: 0,
  RED_MEAT: 1,
  PORK: 2,
  POULTRY: 3,
  FISH: 4,
  SHELLFISH: 5,
  VEGETARIAN: 6,
};
Object.freeze(PROTEIN_CATEGORY);

export const PROTEIN_MAP = {
  redMeat: {
    name: 'beef',
    category: PROTEIN_CATEGORY.RED_MEAT,
  },
  pork: {
    name: 'pork',
    category: PROTEIN_CATEGORY.PORK,
  },
  poultry: {
    name: 'poultry',
    category: PROTEIN_CATEGORY.POULTRY,
  },
  fish: {
    name: 'fish',
    category: PROTEIN_CATEGORY.FISH,
  },
  shellfish: {
    name: 'shellfish',
    category: PROTEIN_CATEGORY.SHELLFISH,
  },
  vegetarian: {
    name: 'vegetarian',
    category: PROTEIN_CATEGORY.VEGETARIAN,
  },
};
Object.freeze(PROTEIN_MAP);

// ref: Symfony \AppBundle\Entity\Ingredient
export const SHOPPING_CATEGORY = {
  UNKNOWN: 0,
  DAIRY: 1,
  MEAT_POULTRY: 2,
  SEAFOOD: 3,
  GROCERY: 4,
  PRODUCE: 5,
  FROZEN_FOODS: 6,
  STAPLE: 7,
};
Object.freeze(SHOPPING_CATEGORY);

export const CATEGORY_MAP = {
  [SHOPPING_CATEGORY.UNKNOWN]: {
    name: 'Other Stuff',
    id: SHOPPING_CATEGORY.UNKNOWN,
    imageName: 'other',
    hint: 'Add other items such as birthday candles, paper towels, or dog food',
  },
  [SHOPPING_CATEGORY.DAIRY]: {
    name: 'Dairy',
    id: SHOPPING_CATEGORY.DAIRY,
    imageName: 'dairy',
  },
  [SHOPPING_CATEGORY.MEAT_POULTRY]: {
    name: 'Meat/Poultry',
    id: SHOPPING_CATEGORY.MEAT_POULTRY,
    imageName: 'pork',
  },
  [SHOPPING_CATEGORY.SEAFOOD]: {
    name: 'Seafood',
    id: SHOPPING_CATEGORY.SEAFOOD,
    imageName: 'fish',
  },
  [SHOPPING_CATEGORY.GROCERY]: {
    name: 'Grocery',
    id: SHOPPING_CATEGORY.GROCERY,
    imageName: 'grocery',
  },
  [SHOPPING_CATEGORY.PRODUCE]: {
    name: 'Produce',
    id: SHOPPING_CATEGORY.PRODUCE,
    imageName: 'produce',
  },
  [SHOPPING_CATEGORY.FROZEN_FOODS]: {
    name: 'Frozen Foods',
    id: SHOPPING_CATEGORY.FROZEN_FOODS,
    imageName: 'frozen',
  },
  [SHOPPING_CATEGORY.STAPLE]: {
    name: 'Staples',
    id: SHOPPING_CATEGORY.STAPLE,
    imageName: '',
  },
};
Object.freeze(CATEGORY_MAP);

export const DIETARY_RESTRICTION = {
  DAIRY_FREE: 'dairyFree',
  GLUTEN_FREE: 'glutenFree',
  PEANUT_FREE: 'peanutFree',
  TREE_NUT_FREE: 'treeNutFree',
  REDUCED_CARB: 'reducedCarb',
  VEGETARIAN: 'vegetarianFoods',
  SOY_FREE: 'soyFree',
  EGG_FREE: 'eggFree',
  STICK_TO_BASICS: 'stickToBasics',
  HEART_HEALTHY: 'heartHealthy',
};
Object.freeze(DIETARY_RESTRICTION);

export const DIETARY_RESTRICTION_MAP = {
  [DIETARY_RESTRICTION.DAIRY_FREE]: {
    id: DIETARY_RESTRICTION.DAIRY_FREE,
    name: 'Dairy-Free',
    hhCompatible: true,
  },
  [DIETARY_RESTRICTION.GLUTEN_FREE]: {
    id: DIETARY_RESTRICTION.GLUTEN_FREE,
    name: 'Gluten-Free',
    hhCompatible: false,
  },
  [DIETARY_RESTRICTION.PEANUT_FREE]: {
    id: DIETARY_RESTRICTION.PEANUT_FREE,
    name: 'Peanut-Free',
    hhCompatible: true,
  },
  [DIETARY_RESTRICTION.TREE_NUT_FREE]: {
    id: DIETARY_RESTRICTION.TREE_NUT_FREE,
    name: 'Tree Nut-Free',
    hhCompatible: true,
  },
  [DIETARY_RESTRICTION.REDUCED_CARB]: {
    id: DIETARY_RESTRICTION.REDUCED_CARB,
    name: 'Reduced Carb',
    hhCompatible: false,
  },
  [DIETARY_RESTRICTION.SOY_FREE]: {
    id: DIETARY_RESTRICTION.SOY_FREE,
    name: 'Soy-Free',
    hhCompatible: false,
  },
  [DIETARY_RESTRICTION.EGG_FREE]: {
    id: DIETARY_RESTRICTION.EGG_FREE,
    name: 'Egg-Free',
    hhCompatible: false,
  },
  [DIETARY_RESTRICTION.STICK_TO_BASICS]: {
    id: DIETARY_RESTRICTION.STICK_TO_BASICS,
    name: 'Stick To The Basics',
    hhCompatible: false,
  },
  [DIETARY_RESTRICTION.HEART_HEALTHY]: {
    id: DIETARY_RESTRICTION.HEART_HEALTHY,
    name: 'Heart Healthy',
    hhCompatible: true,
  },
  [DIETARY_RESTRICTION.VEGETARIAN]: {
    id: DIETARY_RESTRICTION.VEGETARIAN,
    name: 'Vegetarian',
    hhCompatible: true,
  },
};
Object.freeze(DIETARY_RESTRICTION_MAP);

export const SCROLL_BEHAVIOR_START = {
  behavior: 'smooth',
  block: 'start',
};
Object.freeze(SCROLL_BEHAVIOR_START);

export const SCROLL_BEHAVIOR_END = {
  behavior: 'smooth',
  block: 'end',
};
Object.freeze(SCROLL_BEHAVIOR_END);
