import { html, css } from 'lit-element';
import { sharedStyles } from '../theme/shared-styles.js';
import '@material/mwc-textfield';
import '@material/mwc-icon-button-toggle';
import '@material/mwc-button';
import { PageViewElement } from './page-view-element.js';
import { MAX_TEXTFIELD_LENGTH } from '../utilities/constants.js';
import { isValidEmail } from '../utilities/email-validation.js';
import { fireFabContextChangeEvent } from '../utilities/fab-integration.js';
import { getBuildVariant, BUILD_VARIANT } from '../utilities/build-variant.js';
// import { getWebUrl } from '../utilities/url-translation.js';

const FORGOT_PASSWORD_CLICK = 'forgot-click';
const NOT_MEMBER_CLICK = 'not-member-click';

class DdLogin extends PageViewElement {
  static get properties() {
    return {
      heading: { type: String },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        :host {
          display: flex;
          flex-flow: column;
          background-color: var(--mdc-theme-surface);
        }
        #logo,
        .link-container {
          margin: 56px 0 56px 0;
        }

        .dd-login {
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          align-items: center;
        }

        .password-field {
          position: relative;
        }

        #view-pw-toggle {
          position: absolute;
          right: 0px;
          top: 4px;
        }

        .text_button {
          background: transparent;
          color: var(--mdc-theme-on-primary) !important;
        }

        .text_button:hover {
          background: transparent !important;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.heading = 'Log in';
    this._buildVariant = getBuildVariant();
  }

  checkAutofill(selector) {
    const element = this.shadowRoot.querySelector(selector);

    if (element) {
      const label = element.shadowRoot.querySelector('span#label');
      if (label) {
        label.style.transform = 'translateY(-37.25px) scale(1)';
        label.style.backgroundColor = 'white';
        label.style.paddingBottom = '0px';

        return;
      }
    }

    setTimeout(() => {
      this.checkAutofill(selector);
    }, 100);
  }

  firstUpdated() {
    super.firstUpdated();

    if (this.active && this._buildVariant !== BUILD_VARIANT.whiteLabelInIframe) {
      const showFab = true;
      const fabClickCallback = this.onLoginClick_.bind(this);
      fireFabContextChangeEvent(this, 'lock_open', 'Log in', showFab, fabClickCallback);
    }

    this.checkAutofill('#email-text-field');
    this.checkAutofill('#password-text-field');
  }

  render() {
    return html`
      <div class="dd-login">
        <img id="logo" src="images/logo-sm.png" />
        ${this.getFormTemplate_()} ${this.getDesktopFabHostTemplate_()}
      </div>
    `;
  }

  getFormTemplate_() {
    // const lostPasswordUrl = getWebUrl('cms/wp-login.php?action=lostpassword');
    // const homePageUrl = getWebUrl();

    switch (this._buildVariant) {
      case BUILD_VARIANT.whiteLabelInIframe:
        return '';

      case BUILD_VARIANT.whiteLabelInstalled:
        return html`
          <div>
            <mwc-textfield
              id="email-text-field"
              outlined
              label="Email&nbsp;&nbsp;"
              maxLength=${MAX_TEXTFIELD_LENGTH}
              type="email"
              required
              autoCapitalize="off"
            >
            </mwc-textfield>
          </div>
        `;

      case BUILD_VARIANT.tdd:
      default:
        return html`
          <form autocomplete="on" onsubmit="javascript:void(0)" class="dd-login">
            <mwc-textfield
              id="email-text-field"
              outlined
              label="Email&nbsp;&nbsp;"
              maxLength=${MAX_TEXTFIELD_LENGTH}
              type="email"
              required
              autoCapitalize="off"
              name="email"
              autocomplete="email"
            >
            </mwc-textfield>

            <div class="password-field">
              <mwc-textfield
                id="password-text-field"
                outlined
                label="Password&nbsp;&nbsp;"
                maxLength=${MAX_TEXTFIELD_LENGTH}
                type="password"
                required
                autoCapitalize="off"
                name="password"
                autocomplete="current-password"
              >
              </mwc-textfield>
              <mwc-icon-button-toggle
                id="view-pw-toggle"
                onIcon="visibility_off"
                offIcon="visibility"
                @click="${this.onViewPwToggle_}"
              ></mwc-icon-button-toggle>
            </div>

            <mwc-button
              outlined
              class="nav-button dark-filled"
              label="Sign in"
              title="Sign in"
              @click="${this.onLoginClick_}"
            ></mwc-button>
          </form>

          <div class="link-container">
            <mwc-button
              text
              class="text_button"
              label="Forgot your password?"
              title="Forgot your password?"
              @click="${this.onForgotClick_}"
            ></mwc-button>
            <br />
            <mwc-button
              text
              class="text_button"
              label="Not a member?"
              title="Not a member?"
              @click="${this.onNotMemberClick_}"
            ></mwc-button>
          </div>
        `;
    }
  }

  onViewPwToggle_(event) {
    const actionView = event.target.on;
    const passwordTextField = this.shadowRoot.querySelector('#password-text-field');
    passwordTextField.type = actionView ? 'text' : 'password';
  }

  // BUILD defined by webpack
  /* eslint no-undef: "off" */
  onLoginClick_() {
    const email = this.shadowRoot.querySelector('#email-text-field').value;
    let password;

    switch (this._buildVariant) {
      case BUILD_VARIANT.whiteLabelInstalled:
        if (isValidEmail(email)) {
          const event = new CustomEvent('login-submit', {
            detail: {
              email,
              password: BUILD,
            },
          });
          this.dispatchEvent(event);
        }
        break;

      case BUILD_VARIANT.tdd:
        password = this.shadowRoot.querySelector('#password-text-field').value;
        if (isValidEmail(email) && password) {
          const event = new CustomEvent('login-submit', {
            detail: {
              email,
              password,
            },
          });
          this.dispatchEvent(event);
        }
        break;

      case BUILD_VARIANT.whiteLabelInIframe:
      default:
        break;
    }
  }

  onForgotClick_() {
    const event = new CustomEvent(FORGOT_PASSWORD_CLICK);
    this.dispatchEvent(event);
  }

  onNotMemberClick_() {
    const event = new CustomEvent(NOT_MEMBER_CLICK);
    this.dispatchEvent(event);
  }
}

customElements.define('dd-login', DdLogin);
