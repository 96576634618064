import { html, css, LitElement } from 'lit-element';
import '@material/mwc-slider';
import { getIconTemplate } from './template-helpers.js';
import { PROTEIN_CLASS } from '../theme/color-classes.js';
import { sharedStyles } from '../theme/shared-styles.js';

const CHANGED_EVENT = 'changed';

/**
 * option slider with value indicator
 */
export class DdOptionSlider extends LitElement {
  static get properties() {
    return {
      enabled: { type: Boolean },
      name: { type: String },
      min: { type: Number },
      max: { type: Number },
      value: { type: Number },
      proteinCategory: { type: Number, reflect: true },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        .dd-option-slider {
          padding: 8px 0 8px 0;
        }

        /* remove internal 24px margin, subtract 10px thumb knob radius */
        .dd-slider-container {
          width: 200px;
          margin: 0px -14px;
        }

        .dd-flex-container {
          margin-bottom: -8px;
        }

        /* remove 48px margin from slider, scale bar segment, and add margin back */
        .dd-slider-box-20-percent {
          width: calc((1 * (100% - 48px) / 5) + 48px);
        }

        .dd-slider-box-40-percent {
          width: calc((2 * (100% - 48px) / 5) + 48px);
        }

        .dd-slider-box-60-percent {
          width: calc((3 * (100% - 48px) / 5) + 48px);
        }

        .dd-value-size-fade-in-out {
          animation: sizefade 1s ease-in-out;
        }

        /* override standard icon template spacing */
        .dd-icon-placeholder {
          padding: 0;
        }

        #option-value {
          border-radius: 10px;
          padding: 10px 28px;
        }

        @keyframes sizefade {
          0% {
          }
          30% {
            font-size: 20px;
          }
          70% {
            font-size: 20px;
          }
          100% {
          }
        }
      `,
    ];
  }

  constructor() {
    super();

    this.enabled = true;
    this.value = 0;
  }

  updated() {
    super.updated();
    this.layout();
  }

  render() {
    const titleClass = this.enabled ? 'title-container' : 'disabled-title';

    return html`
      <div class="dd-option-slider">
        <div class="dd-flex-container">
          <div class="dd-flex-start-container">
            ${this.getImageTemplate_()}
            <span class="dd-pad-left ${titleClass}">${this.name}</span>
          </div>
          <div class="dd-slider-container">
            <mwc-slider
              class=""
              step="1"
              min="${this.min ?? 0}"
              max="${this.max}"
              value="${this.value}"
              withTickMarks
              discrete
              ?disabled=${!this.enabled}
              @change="${this.onSliderChange_}"
            ></mwc-slider>
          </div>
        </div>
      </div>
    `;
  }

  getCurrentValue() {
    const slider = this.shadowRoot.querySelector('mwc-slider');

    if (!this.enabled || !slider) {
      return 0;
    }

    return slider.value;
  }

  hasChanged() {
    return this.enabled && this.value !== this.getCurrentValue();
  }

  layout() {
    const slider = this.shadowRoot.querySelector('mwc-slider');
    if (slider) {
      slider.layout();
    }
  }

  reset() {
    const slider = this.shadowRoot.querySelector('mwc-slider');
    slider.value = this.value;
  }

  getImageTemplate_() {
    const colorClass = this.enabled ? PROTEIN_CLASS[this.proteinCategory].fg : 'dd-disabled-fg';
    return getIconTemplate('', false, '', '', colorClass, PROTEIN_CLASS[this.proteinCategory].icon);
  }

  onSliderChange_() {
    const changedEvent = new CustomEvent(CHANGED_EVENT, {});
    this.dispatchEvent(changedEvent);
  }
}

customElements.define('dd-option-slider', DdOptionSlider);
