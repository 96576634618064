import {
  DIETARY_RESTRICTION,
  PROTEIN_CATEGORY,
  SHOPPING_CATEGORY,
} from '../utilities/constants.js';

// classes define theme colors in /theme/theme-variables.scss
export const COLOR_CLASSES = [
  'dd-day-menu-0',
  'dd-day-menu-1',
  'dd-day-menu-2',
  'dd-day-menu-3',
  'dd-day-menu-4',
];
Object.freeze(COLOR_CLASSES);

export const SALES_ICON = 'tddi-sale';
export const SALES_FG = 'dd-secondary-fg';
export const VEG_ICON = 'tddi-vegetarian';

export const CATEGORY_CLASS = {
  [SHOPPING_CATEGORY.UNKNOWN]: {
    bg: 'dd-other-circular-bg',
    fg: 'dd-other-fg',
    icon: 'tddi-other',
  },
  [SHOPPING_CATEGORY.DAIRY]: {
    bg: 'dd-dairy-circular-bg',
    fg: 'dd-dairy-fg',
    icon: 'tddi-dairy',
  },
  [SHOPPING_CATEGORY.MEAT_POULTRY]: {
    bg: 'dd-pork-circular-bg',
    fg: 'dd-pork-fg',
    icon: 'tddi-pork',
  },
  [SHOPPING_CATEGORY.SEAFOOD]: {
    bg: 'dd-seafood-circular-bg',
    fg: 'dd-fish-fg',
    icon: 'tddi-fish',
  },
  [SHOPPING_CATEGORY.GROCERY]: {
    bg: 'dd-grocery-circular-bg',
    fg: 'dd-grocery-fg',
    icon: 'tddi-grocery',
  },
  [SHOPPING_CATEGORY.PRODUCE]: {
    bg: 'dd-produce-circular-bg',
    fg: 'dd-produce-fg',
    icon: 'tddi-produce',
  },
  [SHOPPING_CATEGORY.FROZEN_FOODS]: {
    bg: 'dd-frozen-circular-bg',
    fg: 'dd-frozen-fg',
    icon: 'tddi-frozen',
  },
  [SHOPPING_CATEGORY.STAPLE]: {
    bg: 'dd-staples-circular-bg',
    fg: 'dd-secondary-dark-fg',
    icon: 'tddi-staples',
  },
};
Object.freeze(CATEGORY_CLASS);

export const PROTEIN_CLASS = {
  [PROTEIN_CATEGORY.NONE]: {
    bg: '',
    fg: '',
    icon: '',
  },
  [PROTEIN_CATEGORY.RED_MEAT]: {
    bg: 'dd-redmeat-circular-bg',
    fg: 'dd-red-meat-fg',
    icon: 'tddi-red-meat',
  },
  [PROTEIN_CATEGORY.PORK]: {
    bg: 'dd-pork-circular-bg',
    fg: 'dd-pork-fg',
    icon: 'tddi-pork',
  },
  [PROTEIN_CATEGORY.POULTRY]: {
    bg: 'dd-poultry-circular-bg',
    fg: 'dd-poultry-fg',
    icon: 'tddi-poultry',
  },
  [PROTEIN_CATEGORY.FISH]: {
    bg: 'dd-seafood-circular-bg',
    fg: 'dd-fish-fg',
    icon: 'tddi-fish',
  },
  [PROTEIN_CATEGORY.SHELLFISH]: {
    bg: 'dd-shellfish-circular-bg',
    fg: 'dd-shellfish-fg',
    icon: 'tddi-shellfish',
  },
  [PROTEIN_CATEGORY.VEGETARIAN]: {
    bg: 'dd-vegetarian-circular-bg',
    fg: 'dd-vegetarian-fg',
    icon: 'tddi-vegetarian',
  },
};
Object.freeze(PROTEIN_CLASS);

export const DIETARY_RESTRICTION_CLASS = {
  [DIETARY_RESTRICTION.DAIRY_FREE]: {
    fg: 'dd-dairy-fg',
    icon: 'tddi-dairy',
  },
  [DIETARY_RESTRICTION.GLUTEN_FREE]: {
    fg: 'dd-shellfish-fg',
    icon: 'tddi-gluten',
  },
  [DIETARY_RESTRICTION.PEANUT_FREE]: {
    fg: 'dd-shellfish-fg',
    icon: 'tddi-peanut',
  },
  [DIETARY_RESTRICTION.TREE_NUT_FREE]: {
    fg: 'dd-vegetarian-fg',
    icon: 'tddi-tree-nut',
  },
  [DIETARY_RESTRICTION.REDUCED_CARB]: {
    fg: 'dd-other-fg',
    icon: 'tddi-carb',
  },
  [DIETARY_RESTRICTION.SOY_FREE]: {
    fg: 'dd-produce-fg',
    icon: 'tddi-soy',
  },
  [DIETARY_RESTRICTION.EGG_FREE]: {
    fg: 'dd-frozen-fg',
    icon: 'tddi-egg',
  },
  [DIETARY_RESTRICTION.STICK_TO_BASICS]: {
    fg: 'dd-grocery-fg',
    icon: 'ramen_dining',
  },
  [DIETARY_RESTRICTION.HEART_HEALTHY]: {
    fg: 'dd-secondary-fg',
    icon: 'tddi-heart-healty',
  },
  [DIETARY_RESTRICTION.VEGETARIAN]: {
    fg: 'dd-vegetarian-fg',
    icon: 'tddi-vegetarian',
  },
};
Object.freeze(DIETARY_RESTRICTION_CLASS);
