import { html, css } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-formfield';
import '@material/mwc-radio';
import { PageViewElement } from '../page-view-element.js';
import './dd-home-banner.js';
import './dd-mini-menu.js';
import './dd-week-select.js';
import { COLOR_SALES, COLOR_VEG } from './dd-card/dd-callout-card.js';
import './dd-card/dd-news-card.js';
import {
  getIconTemplate,
  getSaleTitleTemplate,
  getTopCenterRoundelTemplate,
} from '../template-helpers.js';
import { sharedStyles } from '../../theme/shared-styles.js';
import { EVENTS } from '../events.js';
import { SALES_ICON, VEG_ICON } from '../../theme/color-classes.js';
import { FAMILY_SIZE } from '../../utilities/constants.js';

const VIEW_MENU_EVENT = 'view-menu';
const VIEW_SL_EVENT = 'view-sl';
// bubbles DdMenuItemCard.day-menu-item-selected event

const NEWS_ICON = 'sticky_note_2';
const NEWS_COLOR_CLASS = 'news-icon-color';

const BLUR_VEG = 'dd-vegetarian-blur';
const BLUR_SECONDARY = 'dd-secondary-blur';

// default quote when no quote is available from news feed
const QUOTE_TEXT = 'Be Yourself. Everyone Else Is Already Taken.';
const QUOTE_AUTHOR = 'oscar wilde';

function getSectionTitleTemplate(line1, line2) {
  return html`
    <div class="title-container">
      <h2>${line1}</h2>
      <h1>${line2}</h1>
    </div>
  `;
}

/**
 * home screen
 */
class DdHome extends PageViewElement {
  static properties = {
    familySize: { type: Number },
    previousWeek: { type: Boolean },
    menuItems: { type: Array },
    news: { Object }, // { featured, memberNews, quote, weeklyTip } ref: test/fixtures/weekly-tip.json items array (https://www.jsonfeed.org/)
    store: { type: String },
    saleCount: { type: Number },
    produceCount: { type: Number },
    saleTitle: { type: String },
    showWeekSelect: { type: Boolean },
  };

  static get styles() {
    return [
      sharedStyles,
      css`
        h4 {
          padding: 32px;
        }

        mwc-radio {
          margin: -4px;
        }

        .section {
          padding: 24px;
        }

        .color-section {
          background-color: var(--mdc-theme-primary-light);
        }

        #banner-section.section {
          padding-bottom: 0;
        }

        #menu-section.section {
          padding-top: 0;
        }

        #weekly-wins-section.section {
          padding-top: 0;
        }

        #week-select-container {
          margin: 16px 0;
        }

        #preferences-container {
          padding: 4px 0 8px 0;
        }

        #callout-container {
          position: relative;
          margin-top: 24px;
          align-items: stretch;
        }

        #callout-container > * {
          flex-grow: 1;
          flex-basis: 0%;
        }

        #sales-callout {
          margin-right: 16px;
        }

        #quote-section > img {
          width: 32px;
          height: 20px;
        }

        #news-section {
          padding-top: 44px;
        }

        .news-container {
          position: relative;
          padding: 16px;
          background-color: var(--mdc-theme-surface);
          border-radius: 20px;
          flex-direction: column;
          align-items: stretch;
        }

        .news-icon-color {
          color: var(--mdc-theme-surface);
          background-color: var(--dd-vegetarian);
        }

        dd-news-card:not(:last-child) {
          margin: 0 0 24px;
        }

        @media only screen and (min-width: 1248px) {
          .section {
            padding: 32px;
          }

          .section-group {
            align-items: start;
            padding: 0 32px;
          }

          .section-group > .dd-flex-two-column-child:first-child {
            margin-right: 32px;
          }

          #menu-section.section {
            padding: 8px 0 0 0;
          }

          #preferences-container {
            padding: 12px 0 16px 0;
          }

          #quote-section.section {
            padding: 48px 0 0 0;
          }

          #weekly-wins-section.section {
            padding: 24px 32px 24px 0;
          }

          #callout-container {
            margin-top: 44px;
          }

          #sl-section.section {
            padding: 24px;
          }

          #sl-section.color-section {
            border-radius: 20px;
          }

          #news-section {
            margin-top: 20px;
          }

          #news-section.section {
            padding: 24px;
          }

          #news-section.color-section {
            background-color: var(--mdc-theme-surface);
          }

          .news-container {
            padding: 36px 24px 24px 24px;
            flex-direction: row;
            column-gap: 24px;
            background-color: var(--mdc-theme-primary-light);
          }

          dd-news-card {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
          }

          dd-news-card:not(:last-child) {
            margin: 0;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
    this.previousWeek = false;
  }

  render() {
    const bannerImagePath = 'images/icon-32x32.png';
    const featuredChipText = 'featured recipe';
    const featuredChipBg = 'dd-poultry-bg';
    const weeklyChipText = 'weekly tip';
    const weeklyChipBg = 'dd-vegetarian-bg';
    const memberChipText = 'member news';
    const memberChipBg = 'dd-member-news-bg';

    let featuredTitle = '';
    let featuredLede = '';
    let featuredImageUrl = '';
    const featuredUrl = '';
    if (this.news.featured && this.news.featured.length) {
      featuredTitle = this.news.featured[0].title;
      featuredLede = this.news.featured[0].contentText;
      featuredImageUrl = this.news.featured[0].image;
    }

    let weeklyTipTitle = '';
    let weeklyTipLede = '';
    let weeklyTipImageUrl = '';
    const weeklyTipUrl = '';
    if (this.news.weeklyTip && this.news.weeklyTip.length) {
      weeklyTipTitle = this.news.weeklyTip[0].title;
      weeklyTipLede = this.news.weeklyTip[0].contentText;
      weeklyTipImageUrl = this.news.weeklyTip[0].image;
    }

    let memberNewsTitle = '';
    let memberNewsLede = '';
    let memberNewsImageUrl = '';
    const memberNewsUrl = '';
    if (this.news.memberNews && this.news.memberNews.length) {
      memberNewsTitle = this.news.memberNews[0].title;
      memberNewsLede = this.news.memberNews[0].contentText;
      memberNewsImageUrl = this.news.memberNews[0].image;
    }

    return html`
      <div>
        <div id="banner-section" class="section">
          <dd-home-banner .image=${bannerImagePath}></dd-home-banner>
        </div>
        ${this.largeScreen
          ? html`
              <div class="dd-flex-container section-group">
                <div class="dd-flex-two-column-child">
                  ${this.getMenuSectionTemplate_()} ${this.getQuoteSectionTemplate_()}
                </div>
                <div class="dd-flex-two-column-child">
                  ${this.getWeeklyWinsSectionTemplate_()} ${this.getSlSectionTemplate_()}
                </div>
              </div>
            `
          : html`
              ${this.getMenuSectionTemplate_()} ${this.getWeeklyWinsSectionTemplate_()}
              ${this.getSlSectionTemplate_()} ${this.getQuoteSectionTemplate_()}
            `}
        <div id="news-section" class="section color-section">
          <div class="news-container dd-flex-container">
            ${getTopCenterRoundelTemplate(NEWS_ICON, NEWS_COLOR_CLASS, BLUR_VEG, true)}
            <dd-news-card
              .chipText=${featuredChipText}
              .colorClass=${featuredChipBg}
              .title=${featuredTitle}
              .lede=${featuredLede}
              .url=${featuredUrl}
              .imageUrl=${featuredImageUrl}
            ></dd-news-card>
            <dd-news-card
              .chipText=${weeklyChipText}
              .colorClass=${weeklyChipBg}
              .title=${weeklyTipTitle}
              .lede=${weeklyTipLede}
              .url=${weeklyTipUrl}
              .imageUrl=${weeklyTipImageUrl}
            ></dd-news-card>
            <dd-news-card
              .chipText=${memberChipText}
              .colorClass=${memberChipBg}
              .title=${memberNewsTitle}
              .lede=${memberNewsLede}
              .url=${memberNewsUrl}
              .imageUrl=${memberNewsImageUrl}
            ></dd-news-card>
          </div>
        </div>
      </div>
      ${this.getSpacerTemplate_()}
    `;
  }

  getStoreTitleTemplate_() {
    const iconName = 'store';
    const colorClass = 'dd-primary-dark-fg';
    const iconBgClass = 'dd-transparent-primary-dark-circular-bg';

    return html`
      <div class="dd-flex-container dd-zero-line-height">
        ${getIconTemplate(iconName, false, iconBgClass, '', colorClass)}
        <div class="dd-left-justify-flex-fill title-container">
          <span class="dd-h5">your store: </span><span class="dd-h5 bold">${this.store}</span>
        </div>
      </div>
    `;
  }

  getPlanTitleTemplate_() {
    const iconBgClass = 'dd-transparent-purple-circular-bg';
    const colorClass = 'dd-purple-fg';

    const isFullSize = this.familySize === FAMILY_SIZE.full.value;
    const iconName = isFullSize ? 'groups' : 'group';
    const familySizeDescription = isFullSize
      ? FAMILY_SIZE.full.description
      : FAMILY_SIZE.half.description;

    return html`
      <div class="dd-flex-container dd-zero-line-height">
        ${getIconTemplate(iconName, false, iconBgClass, '', colorClass)}
        <div class="dd-left-justify-flex-fill title-container">
          <span class="dd-h5">serves: </span
          ><span class="dd-h5 bold">${familySizeDescription}</span>
        </div>
      </div>
    `;
  }

  getMenuSectionTemplate_() {
    const weekSelectVisbilityClass = this.showWeekSelect ? 'dd-visible' : 'dd-invisible';

    return html`
      <div id="menu-section" class="section">
        <div id="week-select-container" class="dd-flex-container">
          ${getSectionTitleTemplate('your menu', 'this week')}
          <dd-week-select
            class="${weekSelectVisbilityClass}"
            ?previousWeek=${this.previousWeek}
            @week-action="${this.onSelectWeekAction_}"
          ></dd-week-select>
        </div>
        <dd-mini-menu .clickable=${true} .menuItems=${this.menuItems}></dd-mini-menu>
        <div id="preferences-container">
          <mwc-button
            label="change preferences"
            class="hint-button"
            @click="${this.onMenuLimitsClick_}"
          ></mwc-button>
        </div>
        <div class="dd-flex-end-container">
          <mwc-button
            outlined
            icon="visibility"
            label="view"
            class="light-filled dd-button-margin-right"
            @click="${this.onViewMenuClick_}"
          ></mwc-button>
          <mwc-button
            outlined
            icon="print"
            label="print"
            class="dark-filled"
            @click="${this.onPrintMenuClick_}"
          ></mwc-button>
        </div>
      </div>
    `;
  }

  getQuoteSectionTemplate_() {
    const quoteImagePath = 'images/quote.png';
    const author =
      this.news.quote && this.news.quote.length ? this.news.quote[0].title : QUOTE_AUTHOR;
    const text =
      this.news.quote && this.news.quote.length ? this.news.quote[0].contentText : QUOTE_TEXT;

    return html`
      <div id="quote-section" class="section dd-flex-start-container ">
        <img src="/${quoteImagePath}" />
        <div class="dd-quote-text">
          <h5>${text}</h5>
          <p class="dd-quote-name">${author}</p>
        </div>
      </div>
    `;
  }

  getSlSectionTemplate_() {
    return html`
      <div id="sl-section" class="section color-section">
        ${getSectionTitleTemplate('your shopping list', 'this week')}
        <div class="dd-expanded-content-block">
          <div class="dd-margin-bottom">
            ${getSaleTitleTemplate(this.saleTitle)}
          </div>
          <div class="dd-margin-bottom dd-flex-container">
            ${this.getStoreTitleTemplate_()}
            <mwc-button label="change" @click="${this.onChangeStoreClick_}"></mwc-button>
          </div>
          <div class="dd-flex-container">
            ${this.getPlanTitleTemplate_()}
            <mwc-button label="change" @click="${this.onChangePlanClick_}"></mwc-button>
          </div>
        </div>
        <div class="dd-flex-end-container">
          <mwc-button
            outlined
            icon="visibility"
            label="view"
            class="white-filled dd-button-margin-right"
            @click="${this.onViewSlClick_}"
          ></mwc-button>
          <mwc-button
            outlined
            icon="print"
            label="print"
            class="dark-filled"
            @click="${this.onPrintSlClick_}"
          ></mwc-button>
        </div>
      </div>
    `;
  }

  getWeeklyWinsSectionTemplate_() {
    const saleExhortation = 'Happy Savings!';
    const vegExhoration = 'Good for You!';

    return html`
      <div id="weekly-wins-section" class="section">
        ${getSectionTitleTemplate('your weekly', 'wins')}
        <div id="callout-container" class="dd-flex-container">
          <dd-callout-card
            id="sales-callout"
            .quantity=${this.saleCount}
            .bodyPrefix=${'Your Menu uses '}
            .bodySuffix=${` specials at ${this.store} this week.`}
            .exhortation=${saleExhortation}
            .color=${COLOR_SALES}
            .blurClass=${BLUR_SECONDARY}
            .iconClass=${SALES_ICON}
          >
          </dd-callout-card>
          <dd-callout-card
            .quantity=${this.produceCount}
            .bodyPrefix=${'Your Menu uses '}
            .bodySuffix=${' fruits and veggies.'}
            .exhortation=${vegExhoration}
            .color=${COLOR_VEG}
            .blurClass=${BLUR_VEG}
            .iconClass=${VEG_ICON}
          >
          </dd-callout-card>
        </div>
      </div>
    `;
  }

  onSelectWeekAction_() {
    const event = new CustomEvent(EVENTS.CHANGE_WEEK_MENU, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onMenuLimitsClick_() {
    const event = new CustomEvent(EVENTS.MENU_LIMITS_CLICK, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onViewMenuClick_() {
    const event = new CustomEvent(VIEW_MENU_EVENT, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onPrintMenuClick_() {
    const event = new CustomEvent(EVENTS.PRINT_MENU, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onViewSlClick_() {
    const event = new CustomEvent(VIEW_SL_EVENT, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onPrintSlClick_() {
    const event = new CustomEvent(EVENTS.PRINT_SL, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onChangeStoreClick_() {
    const event = new CustomEvent(EVENTS.CHANGE_STORE, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onChangePlanClick_() {
    const event = new CustomEvent(EVENTS.CHANGE_FAMILY_SIZE, {
      detail: {},
    });
    this.dispatchEvent(event);
  }
}

customElements.define('dd-home', DdHome);
