import { LOGIN_VIEW, navigate, PUBLIC_VIEWS } from '../actions/navigation';
import { setToken } from '../actions/token';
import { TOKEN_UNAUTHORIZED } from '../utilities/constants.js';

export const TOKEN_KEY = 'token';
export const REFRESH_TOKEN_KEY = 'refreshToken';

/**
 * store listener that manages tokens in state and Local Storage
 */
export class TokenListener {
  constructor(store) {
    this._store = store;
  }

  /**
   * usage: store.subscribe(tokenListener.subscribeHandler)
   */
  subscribeHandler() {
    const state = this._store.getState();
    if (state.api.isFetching) {
      return;
    }

    const queryToken = state.router.queries.token;
    const queryRefreshToken = state.router.queries.refresh_token;
    const stateAuthToken = state.token.auth;
    const stateRefreshToken = state.token.refresh;
    const stateNeedsToken = !stateAuthToken || !stateRefreshToken;

    let storedToken = '';
    let storedRefreshToken = '';
    let localStorageEnabled = true;

    try {
      if (stateRefreshToken === TOKEN_UNAUTHORIZED) {
        localStorage.setItem(TOKEN_KEY, '');
        localStorage.setItem(REFRESH_TOKEN_KEY, '');
      }

      storedToken = localStorage.getItem(TOKEN_KEY);
      storedRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    } catch (error) {
      localStorageEnabled = false;
    }

    // local storage values take precedence over query params in case of reload
    const isNewTokenQueryParam = queryToken && queryToken !== stateAuthToken;
    const isNewRefreshTokenQueryParam =
      queryRefreshToken && queryRefreshToken !== stateRefreshToken;
    const localStorageNeedsToken = !storedToken || !storedRefreshToken;
    const updateFromQuery =
      isNewTokenQueryParam && isNewRefreshTokenQueryParam && localStorageNeedsToken;

    if (updateFromQuery) {
      if (localStorageEnabled) {
        localStorage.setItem(TOKEN_KEY, queryToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, queryRefreshToken);
      }

      this._store.dispatch(setToken(queryToken, queryRefreshToken));
    } else if (stateNeedsToken) {
      if (storedToken) {
        this._store.dispatch(setToken(storedToken, storedRefreshToken));
        //   } else if (!isOnboardingCompleted) {
        //     this._store.dispatch(navigate(ONBOARDING_VIEW));
      } else if (!PUBLIC_VIEWS.includes(state.navigation.view)) {
        this._store.dispatch(navigate(LOGIN_VIEW));
      }
    } else if (
      (stateAuthToken !== storedToken || stateRefreshToken !== storedRefreshToken) &&
      localStorageEnabled
    ) {
      localStorage.setItem(TOKEN_KEY, stateAuthToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, stateRefreshToken);
    }
  }
}
