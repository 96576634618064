/**
 * profile data action
 */

export const SET_PROFILE = 'SET_PROFILE';

export function setProfile(payload) {
  return {
    type: SET_PROFILE,
    payload,
  };
}
