import { html, css, LitElement } from 'lit-element';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './onboarding/dd-onboarding.scss';
import { PLAN_1, PLAN_2, SubscriptionPlans } from '../../resources/subscription-plans.js';
import './dd-card/dd-plan-option-card.js';

function getOptionCardsTemplate(selectedPlan) {
  return html`
    <div class="dd-host-centered option-cards-container">
      <dd-plan-option-card
        .selected=${selectedPlan === SubscriptionPlans[PLAN_1].id}
        .promoted=${false}
        .option=${SubscriptionPlans[PLAN_1]}
      ></dd-plan-option-card>
      <dd-plan-option-card
        .selected=${selectedPlan === SubscriptionPlans[PLAN_2].id || selectedPlan === ''}
        .promoted=${true}
        .option=${SubscriptionPlans[PLAN_2]}
      ></dd-plan-option-card>
    </div>
  `;
}

/**
 * subscription selector
 */
class DdSubscriptionSelect extends LitElement {
  static get properties() {
    return {
      familySize: { type: Number },
      store: { type: String },
      planId: { type: String },
      _selectedStateCode: { type: String },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        .option-cards-container {
          column-gap: 16px;
          margin: 18px 0 16px 0;
          align-items: center;
          flex-direction: column;
          gap: 32px;
        }

        @media only screen and (min-width: 1248px) {
          .option-cards-container {
            column-gap: 24px;
            margin: 18px 0 16px 0;
            flex-direction: row;
          }
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="wrapper">
        <h2 class="heading-margin">
          Enjoy a full 14 days to try it for FREE! You will be charged nothing during the trial, and
          you can cancel anytime.
        </h2>
        <div class="split-section">
          <div class="split-section-child">
            <div class="dd-content-block">
              ${getOptionCardsTemplate(this.planId)}
            </div>
          </div>
        </div>

        <h5 class="normal">
          As soon as you sign up, we'll create your first personalized menu, based on your store's
          specials, in minutes!
        </h5>
      </div>
    `;
  }
}

customElements.define('dd-subscription-select', DdSubscriptionSelect);
