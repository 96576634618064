/**
 * base module used by endpoint API actions
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 */

export { default as axios } from 'axios';
export { getApiUrl, getAuthUrl, getParameters } from '../../utilities/url-translation.js';
export { API_REQUEST, startRequest, completeRequest } from './api.js';

export const UPDATE_COMPLETE_HINT = 'Just a moment while we get your menu ready!';
export const NOK_RESPONSE_HINT =
  'Sorry, we have encountered a problem. Please contact support@thedinnerdaily.com';
export const UNABLE_TO_CREATE_MENU_HINT = ''; // silent it's occurences
