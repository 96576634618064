import { html, css, LitElement } from 'lit-element';
import '@material/mwc-formfield';
import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-select';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './onboarding/dd-onboarding.scss';
import { StateNames } from '../../resources/state-names.js';
import { StatesStores } from '../../resources/states-stores.js';
import { Stores } from '../../resources/stores.js';

const STATE_SELECTED_EVENT = 'state-selected';
const STORE_SELECTED_EVENT = 'store-selected';

function getDefaultStateCode(storeName) {
  const defaultStore = Stores.find(storeItem => storeItem.internalName === storeName);

  let defaultStateCode = '';
  if (defaultStore && defaultStore.states && defaultStore.states.length) {
    defaultStateCode = defaultStore.states[0];
  }

  return defaultStateCode;
}

function getStateListItemTemplates(storeName = '', statePreselected = false) {
  const defaultStateCode = storeName ? getDefaultStateCode(storeName) : '';

  return StateNames.map(stateName => {
    const selected = !statePreselected && stateName.code === defaultStateCode;
    return html`
      <mwc-list-item value="${stateName.code}" ?selected="${selected}"
        >${stateName.state}</mwc-list-item
      >
    `;
  });
}

/**
 * store selector
 */
class DdStoreSelect extends LitElement {
  static get properties() {
    return {
      familySize: { type: Number },
      store: { type: String },
      _selectedStateCode: { type: String },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        mwc-icon {
          --mdc-icon-size: 24px;
          font-size: 24px;
          flex-grow: 0;
          margin-right: 16px;
        }

        mwc-formfield {
          flex-grow: 1;
        }

        p.hint {
          margin: 0 0 32px 0;
          line-height: 24px;
          color: #5d5d5d;
        }

        span.hint {
          font-size: 12px;
          line-height: 14px;
          color: #5d5d5d;
          span {
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
            color: #5d5d5d;
          }
        }

        .dd-line-divider {
          margin: 16px 0;
        }

        .store-img {
          width: 100%;
          height: auto;
        }

        @media only screen and (min-width: 1248px) {
          .dd-line-divider {
            display: none;
          }
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="store-select" class="split-section">
        <div class="split-section-child">
          <h2 class="heading-margin">Select your <span class="dd-h2 bold">Store</span></h2>
          <p class="hint">
            Even though you select a primary store below, you'll still have access to all the stores
            we offer menus for.
          </p>
          <div class="dd-content-block">
            <mwc-select
              id="state-select"
              label="State&nbsp;&nbsp;"
              class="dd-full-width"
              outlined
              .value=${this._selectedStateCode}
              @action="${this.onStateAction_}"
            >
              ${getStateListItemTemplates('', this._selectedStateCode)}
            </mwc-select>
          </div>
          <div class="dd-content-block">
            <mwc-select
              id="store-select"
              class="dd-full-width"
              outlined
              label="Store&nbsp;&nbsp;"
              ?disabled=${!this._selectedStateCode}
              .value="${this.store}"
              @action="${this.onStoreAction_}"
            >
              ${this.getStoreListItemTemplates_()}
            </mwc-select>
          </div>
          <span class="dd-caption hint"
            ><span>Don’t see your store?</span> Select Any Store and you will still receive an easy
            and personalized menu. The only difference with the Any Store option is your menu will
            not be based on the weekly sales flyer at a particular store.</span
          >
          <div class="dd-line-divider"></div>
        </div>
        <div class="split-section-child">
          <div class="dd-flex-container">
            <img id="logo" src="images/onboarding/store-select.png" class="store-img" />
          </div>
        </div>
      </div>
    `;
  }

  focus() {
    const stateSelect = this.shadowRoot.getElementById('state-select');
    if (stateSelect) {
      stateSelect.focus();
    }
  }

  getStoreListItemTemplates_() {
    const storesInState = StatesStores[this._selectedStateCode];

    if (storesInState) {
      storesInState.sort((storeA, storeB) => {
        if (storeA.internalName < storeB.internalName) {
          return -1;
        }
        if (storeA.internalName > storeB.internalName) {
          return 1;
        }

        return 0;
      });

      return storesInState.map(storeItem => {
        const name = storeItem.alias ? storeItem.alias : storeItem.internalName;

        const selected = storeItem.internalName === this.store;
        return html`
          <mwc-list-item value="${storeItem.internalName}" ?selected="${selected}"
            >${name}</mwc-list-item
          >
        `;
      });
    }

    return '';
  }

  onStateAction_(event) {
    this._selectedStateCode = event.target.value;

    const newEvent = new CustomEvent(STATE_SELECTED_EVENT, {
      bubbles: true,
      composed: true,
      detail: { state: this._selectedStateCode },
    });
    this.dispatchEvent(newEvent);

    this.store = '';
    const newStoreEvent = new CustomEvent(STORE_SELECTED_EVENT, {
      detail: {
        store: '',
      },
    });
    this.dispatchEvent(newStoreEvent);
  }

  onStoreAction_(event) {
    // can be any store, including existing
    if (event.target.value) {
      this.store = event.target.value;

      const newEvent = new CustomEvent(STORE_SELECTED_EVENT, {
        detail: {
          store: event.target.value,
          isSilent: true,
        },
      });
      this.dispatchEvent(newEvent);
    }
  }
}

customElements.define('dd-store-select', DdStoreSelect);
