import { SET_PROFILE } from '../actions/profile';

export const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
};

export function profile(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        firstName: action.payload.first,
        lastName: action.payload.last,
        email: action.payload.email,
      };
    default:
      return state;
  }
}
