import { html } from 'lit-element';
import cloneDeep from 'lodash-es/cloneDeep.js';
import isEqual from 'lodash-es/isEqual.js';
import '@material/mwc-icon-button';
import '@material/mwc-button';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-select';
import '@material/mwc-textarea';
import '@material/mwc-textfield';
import { CARD_BODY_ID, DdCard2 } from './dd-card-2.js';
import './dd-card-expander.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-editable-recipe-card-2.scss';
import {
  FULL_WIDTH_CONTENT_ID,
  BOTTOM_RIGHT_CTL_ID,
  REMOVE_WIDTH,
  REPLACE_WIDTH,
} from '../../dd-card/dd-card.js';
import './dd-recipe-ingredient-card-2.js';
import { getProteinIconTemplate } from '../../template-helpers.js';
import '../../dd-expandable-content.js';
import {
  ASYNC_PAUSE,
  INVALID_ID,
  MAX_TEXTFIELD_LENGTH,
  VALID_CHAR_REGEX,
  VALIDATION_MESSAGE,
} from '../../../utilities/constants.js';
import { TEMPORARY_ID } from '../../../utilities/new-item-id.js';
import { isArray, isNumericallyEqual } from '../../../utilities/object-evaluation.js';
import { EVENTS } from '../../events.js';

class DdEditableRecipeCard2 extends DdCard2 {
  static get properties() {
    return {
      recipe: { type: Object },
    };
  }

  static get styles() {
    return [sharedStyles, style];
  }

  set recipe(value) {
    if (!isEqual(value, this._recipe)) {
      const changedFromTemp = this.isTempRecipe && value.id;
      if (changedFromTemp) {
        //	this.showFab_();
      }

      this._recipe = value;
      this.requestUpdate();
    }
  }

  get recipe() {
    return this._recipe;
  }

  get isTempRecipe() {
    return this.recipe.id === TEMPORARY_ID;
  }

  constructor() {
    super();
    // overrides
    this._allowPanMinimized = true;
    this._swipeLeftReveal = true;
    this._swipeLeftRevealRange = REPLACE_WIDTH + REMOVE_WIDTH;
    this._cardBodyMinClass = 'dd-editable-recipe-card-minimize';
    this._cardBodySmallScreenMaxClass = 'dd-editable-recipe-card-body-maximize';
    this._cardBodyLargeScreenMaxClass = 'dd-editable-recipe-lg-screen-maximize';
    this._cardBodyOverlayClass = 'dd-editable-recipe-overlay';
    this._cardBodyOverlayMiddleClass = 'dd-editable-recipe-overlay-middle';

    this._recipe = {};
    this._hasControlRow = true;
  }

  updated() {
    super.updated();
  }

  render() {
    const proteinCategory = this.recipe.proteinCategory;

    return html`
      <div id="${CARD_BODY_ID}" class="dd-card-body-2">
        <dd-card-expander
          ?isOpen=${this.isOpen}
          ?clickableTitle=${true}
          @pre-open="${this.onExpanderOpen_}"
          @close="${this.onExpanderClose_}"
        >
          ${this.getTitleTemplate_(proteinCategory, true)}
          <div slot="content">
            ${this.getNameAndContentTemplate_()}
          </div>
        </dd-card-expander>
      </div>
    `;
  }

  layout_() {
    const prepTimeSelect = this.shadowRoot.querySelector('#prep-time-select');
    const cookTimeSelect = this.shadowRoot.querySelector('#cook-time-select');
    if (prepTimeSelect && cookTimeSelect) {
      prepTimeSelect.layout(true);
      cookTimeSelect.layout(true);
    }

    const nameField = this.shadowRoot.querySelector('#name-text-field');
    if (nameField) {
      nameField.layout();
      if (this.isOpen && this.isTempRecipe) {
        setTimeout(() => {
          if (nameField.mdcFoundation) {
            nameField.mdcFoundation.setValid(true);
          }
          nameField.isUiValid = true;
          nameField.focus();
        }, ASYNC_PAUSE);
      }
    }
  }

  getTitleTemplate_(proteinCategory, isPersonalRecipe) {
    return html`
      <div class="dd-flex-container dd-zero-line-height" slot="title">
        ${getProteinIconTemplate(proteinCategory, isPersonalRecipe)}
        <div class="dd-left-justify-flex-fill">
          <h5>${this.getTitle_()}</h5>
        </div>
      </div>
    `;
  }

  getNameAndContentTemplate_() {
    const existingRecipe = this.recipe.id !== INVALID_ID;

    return html`
      <div class="content-container">
        <mwc-textfield
          id="name-text-field"
          class="dd-full-width"
          outlined
          label="Name&nbsp;&nbsp;"
          ?autoValidate=${existingRecipe}
          maxLength=${MAX_TEXTFIELD_LENGTH}
          pattern=${VALID_CHAR_REGEX}
          validationMessage=${VALIDATION_MESSAGE}
          @input="${this.onNameInput_}"
          @change="${this.onTextChange_}"
          required
        >
        </mwc-textfield>
      </div>
      ${this.getMobileContentTemplate_()}
    `;
  }

  getPrepTimeSelectTemplate_() {
    return html`
      <mwc-select
        id="prep-time-select"
        outlined
        fixedMenuPosition
        style="width: 140px; width: 140px;"
        label="Preparation time&nbsp;&nbsp;"
        @action="${this.onSelectAction_}"
      >
        <mwc-list-item value="0">0 min</mwc-list-item>
        <mwc-list-item value="1">1 min</mwc-list-item>
        <mwc-list-item value="2">2 min</mwc-list-item>
        <mwc-list-item value="3">3 min</mwc-list-item>
        <mwc-list-item value="5">5 min</mwc-list-item>
        <mwc-list-item value="10">10 min</mwc-list-item>
        <mwc-list-item value="12">12 min</mwc-list-item>
        <mwc-list-item selected value="15">15 min</mwc-list-item>
        <mwc-list-item value="20">20 min</mwc-list-item>
        <mwc-list-item value="25">25 min</mwc-list-item>
        <mwc-list-item value="30">30 min</mwc-list-item>
        <mwc-list-item value="35">35 min</mwc-list-item>
        <mwc-list-item value="40">40 min</mwc-list-item>
        <mwc-list-item value="50">50 min</mwc-list-item>
      </mwc-select>
    `;
  }

  getCookTimeSelectTemplate_() {
    return html`
      <mwc-select
        id="cook-time-select"
        outlined
        fixedMenuPosition
        label="Cooking time&nbsp;&nbsp;"
        @action="${this.onSelectAction_}"
      >
        <mwc-list-item value="0">0 min</mwc-list-item>
        <mwc-list-item value="4">4 min</mwc-list-item>
        <mwc-list-item value="5">5 min</mwc-list-item>
        <mwc-list-item value="6">6 min</mwc-list-item>
        <mwc-list-item value="8">8 min</mwc-list-item>
        <mwc-list-item value="10">10 min</mwc-list-item>
        <mwc-list-item value="12">12 min</mwc-list-item>
        <mwc-list-item value="14">14 min</mwc-list-item>
        <mwc-list-item selected value="15">15 min</mwc-list-item>
        <mwc-list-item value="18">18 min</mwc-list-item>
        <mwc-list-item value="20">20 min</mwc-list-item>
        <mwc-list-item value="25">25 min</mwc-list-item>
        <mwc-list-item value="30">30 min</mwc-list-item>
        <mwc-list-item value="35">35 min</mwc-list-item>
        <mwc-list-item value="40">40 min</mwc-list-item>
        <mwc-list-item value="45">45 min</mwc-list-item>
        <mwc-list-item value="50">50 min</mwc-list-item>
        <mwc-list-item value="55">55 min</mwc-list-item>
        <mwc-list-item value="60">1 hr</mwc-list-item>
        <mwc-list-item value="80">1 hr 20 min</mwc-list-item>
        <mwc-list-item value="90">1 hr 30 min</mwc-list-item>
        <mwc-list-item value="100">1 hr 40 min</mwc-list-item>
        <mwc-list-item value="120">2 hr</mwc-list-item>
        <mwc-list-item value="150">2 hr 30 min</mwc-list-item>
        <mwc-list-item value="180">3 hr</mwc-list-item>
        <mwc-list-item value="210">3 hr 30 min</mwc-list-item>
      </mwc-select>
    `;
  }

  getMobileContentTemplate_() {
    if (!this.recipe.id) {
      return html`
        <div id="name-button-container" class="dd-flex-end-container dd-content-container dd_hide">
          <mwc-button @click="${this.onCreateClick_}">create</mwc-button>
        </div>
      `;
    }

    return html`
      <div class="dd-flex-container content-container">
        ${this.getPrepTimeSelectTemplate_()} ${this.getCookTimeSelectTemplate_()}
      </div>
      <div id="ingredients-overline" class="dd-overline">ingredients</div>
      <div id="${FULL_WIDTH_CONTENT_ID}">
        ${this.getRecipeIngredientTemplates_()}
      </div>
      <div class="dd-flex-end-container dd-add-button">
        <mwc-button
          id="add-button"
          outlined
          class="light-filled dd_show"
          icon="add_circle"
          @click="${this.onAddRecipeIngredientClick_}"
          >add ingredient</mwc-button
        >
      </div>
      <div class="dd-editable-content-container">
        <dd-expandable-content
          ?clickableTitle=${true}
          ?isOpen=${true}
          @open="${this.onInstructionsOpen_}"
        >
          <span slot="title" class="dd-overline">${'instructions'}</span>
          <div slot="content">
            <mwc-textarea
              id="instruction-textarea"
              class="dd-full-width"
              outlined
              placeholder="Write your instructions here"
              .value="${this.recipe.instructions ?? ''}"
              rows="10"
              maxLength="4096"
              @blur="${this.onTextChange_}"
              @keypress="${this.onKeypress_}"
            >
            </mwc-textarea>
          </div>
        </dd-expandable-content>
        <dd-expandable-content
          ?clickableTitle=${true}
          ?isOpen=${false}
          @open="${this.onCornerNoteOpen_}"
        >
          <span slot="title" class="dd-overline">${'note'}</span>
          <div slot="content">
            <mwc-textarea
              id="corner-note-textarea"
              class="dd-full-width"
              outlined
              placeholder="Enter any notes here"
              .value="${this.recipe.cornerNote ?? ''}"
              rows="10"
              maxLength="4096"
              @blur="${this.onTextChange_}"
              @keypress="${this.onKeypress_}"
            ></mwc-textarea>
          </div>
        </dd-expandable-content>
      </div>
      <div id="${BOTTOM_RIGHT_CTL_ID}" class="dd-flex-end-container">
        <mwc-icon-button icon="delete_outline" @click="${this.onDeleteClick_}"> </mwc-icon-button>
      </div>
    `;
  }

  getRecipeIngredientTemplates_() {
    if (isArray(this.recipe.ingredients) && this.recipe.ingredients.length > 0) {
      return this.recipe.ingredients.map(
        ingredient => html`
          <dd-recipe-ingredient-card-2
            ?isOpen=${ingredient.id === TEMPORARY_ID}
            .recipeIngredient=${ingredient}
            @create="${this.onCreateRecipeIngredient_}"
            @changed="${this.onChangedRecipeIngredient_}"
            @delete="${this.onDeleteRecipeIngredient_}"
            @maximize-card="${this.onMaximizeCarouselCard_}"
            @minimize-card="${this.onMinimizeCarouselCard_}"
          >
          </dd-recipe-ingredient-card-2>
        `,
      );
    }

    return '';
  }

  getCurrentRecipeDto_() {
    const nameField = this.shadowRoot.querySelector('#name-text-field');
    const instructionsArea = this.shadowRoot.querySelector('#instruction-textarea');
    const cornerNoteArea = this.shadowRoot.querySelector('#corner-note-textarea');
    const prepTimeSelect = this.shadowRoot.querySelector('#prep-time-select');
    const cookTimeSelect = this.shadowRoot.querySelector('#cook-time-select');

    const recipeDto = cloneDeep(this.recipe);
    recipeDto.name = nameField.value;
    recipeDto.instructions = instructionsArea.value;
    recipeDto.cornerNote = cornerNoteArea.value;
    recipeDto.preparationTime = parseInt(prepTimeSelect.value, 10);
    recipeDto.cookingTime = parseInt(cookTimeSelect.value, 10);

    return recipeDto;
  }

  updateFromState_() {
    const nameField = this.shadowRoot.querySelector('#name-text-field');
    nameField.value = this.recipe.name ?? '';

    const prepTimeSelect = this.shadowRoot.querySelector('#prep-time-select');
    if (prepTimeSelect) {
      const cookTimeSelect = this.shadowRoot.querySelector('#cook-time-select');
      this.setSelected_(prepTimeSelect, this.recipe.preparationTime);
      this.setSelected_(cookTimeSelect, this.recipe.cookingTime);
    }
  }

  isRecipeTextChanged_() {
    const nameField = this.shadowRoot.querySelector('#name-text-field');
    const instructionsArea = this.shadowRoot.querySelector('#instruction-textarea');
    const cornerNoteArea = this.shadowRoot.querySelector('#corner-note-textarea');

    const bothInstructionsNullOrEmpty =
      !this.recipe.instructions && instructionsArea && !instructionsArea.value;
    const instructionsChanged =
      instructionsArea &&
      !bothInstructionsNullOrEmpty &&
      this.recipe.instructions !== instructionsArea.value;
    const bothCornerNotesNullOrEmpty =
      !this.recipe.cornerNote && cornerNoteArea && !cornerNoteArea.value;
    const cornerNoteChanged =
      cornerNoteArea &&
      !bothCornerNotesNullOrEmpty &&
      this.recipe.cornerNote !== cornerNoteArea.value;

    const bothNamesNullOrEmpty = !this.recipe.name && !nameField.value;
    const nameChanged = !bothNamesNullOrEmpty && this.recipe.name !== nameField.value;
    const contentChanged = nameChanged || instructionsChanged || cornerNoteChanged;

    return !this.isTempRecipe && contentChanged;
  }

  isRecipeChanged_() {
    const prepTimeSelect = this.shadowRoot.querySelector('#prep-time-select');
    const cookTimeSelect = this.shadowRoot.querySelector('#cook-time-select');
    const prepTimeChanged =
      prepTimeSelect &&
      prepTimeSelect.value &&
      !isNumericallyEqual(this.recipe.preparationTime, prepTimeSelect.value);
    const cookingTimeChanged =
      cookTimeSelect &&
      cookTimeSelect.value &&
      !isNumericallyEqual(this.recipe.cookingTime, cookTimeSelect.value);
    const contentChanged = this.isRecipeTextChanged_() || prepTimeChanged || cookingTimeChanged;

    return !this.isTempRecipe && contentChanged;
  }

  getTitle_() {
    return this.recipe.name ?? 'my new recipe';
  }

  onKeypress_(event) {
    if (event.key === 'Enter') {
      this.onTextChange_();
    }
  }

  onTextChange_() {
    const nameField = this.shadowRoot.querySelector('#name-text-field');

    if (nameField.checkValidity() && this.isRecipeTextChanged_()) {
      const dto = this.getCurrentRecipeDto_();
      this.fireGlobalEvent_(EVENTS.CHANGED_PERSONAL_RECIPE, dto);
    }
  }

  onSelectAction_() {
    const nameField = this.shadowRoot.querySelector('#name-text-field');

    if (nameField.checkValidity() && this.isRecipeChanged_()) {
      const dto = this.getCurrentRecipeDto_();
      this.fireGlobalEvent_(EVENTS.CHANGED_PERSONAL_RECIPE, dto);
    }
  }

  onNameInput_() {
    const createButtonContainer = this.shadowRoot.querySelector('#name-button-container');
    const nameField = this.shadowRoot.querySelector('#name-text-field');

    if (nameField.reportValidity()) {
      if (createButtonContainer && !this.recipe.id) {
        createButtonContainer.classList.replace('dd_hide', 'dd_show');
      }
    } else if (!this.recipe.id && createButtonContainer) {
      createButtonContainer.classList.replace('dd_show', 'dd_hide');
    }
  }

  onAddRecipeIngredientClick_(event) {
    const detail = {
      id: TEMPORARY_ID,
      recipeId: this.recipe.id,
    };
    this.fireGlobalEvent_(EVENTS.NEW_RECIPE_INGREDIENT, detail);
    event.target.classList.replace('dd_show', 'dd_hide');
  }

  onCreateRecipeIngredient_(event) {
    const detail = {
      id: TEMPORARY_ID,
      recipeId: this.recipe.id,
      ...event.detail,
    };
    this.fireGlobalEvent_(EVENTS.NEW_RECIPE_INGREDIENT, detail);

    const addButton = this.shadowRoot.querySelector('#add-button');
    addButton.classList.replace('dd_hide', 'dd_show');
  }

  onChangedRecipeIngredient_(event) {
    const detail = {
      recipeId: this.recipe.id,
      ...event.detail,
    };
    this.fireGlobalEvent_(EVENTS.CHANGED_RECIPE_INGREDIENT, detail);
  }

  onDeleteRecipeIngredient_(event) {
    const detail = {
      recipeId: this.recipe.id,
      ...event.detail,
    };
    this.fireGlobalEvent_(EVENTS.REMOVE_RECIPE_INGREDIENT, detail);

    const addButton = this.shadowRoot.querySelector('#add-button');
    addButton.classList.replace('dd_hide', 'dd_show');
  }

  onCreateClick_() {
    const nameField = this.shadowRoot.querySelector('#name-text-field');
    const detail = {
      id: this.recipe.id,
      dishType: this.recipe.dishType,
      name: nameField.value,
    };
    this.fireLocalEvent_(EVENTS.CREATE_PERSONAL_RECIPE, detail);
  }

  onInstructionsOpen_() {
    const instructionsArea = this.shadowRoot.querySelector('#instruction-textarea');
    instructionsArea.focus();
  }

  onCornerNoteOpen_() {
    const cornerNoteArea = this.shadowRoot.querySelector('#corner-note-textarea');
    cornerNoteArea.focus();
  }

  onExpanderOpen_() {
    this.layout_();

    const detail = {
      id: this.recipe.id,
    };
    this.fireLocalEvent_(EVENTS.MAXIMIZE, detail);
  }

  onExpanderClose_() {
    const detail = {
      id: this.recipe.id,
    };
    this.fireLocalEvent_(EVENTS.MINIMIZE, detail);
  }

  onDeleteClick_() {
    const detail = { recipeId: this.recipe.id };
    this.fireGlobalEvent_(EVENTS.DELETE_PERSONAL_RECIPE, detail);
    this.close();
    this.isActive = false;
  }

  // BEGIN Recipe Ingredient card carousel methods
  onMaximizeCarouselCard_(event) {
    const ingredientCards = this.shadowRoot.querySelectorAll('dd-recipe-ingredient-card-2');
    ingredientCards.forEach(card => {
      if (card.recipeIngredient.id !== event.detail.id) {
        card.close();
      }
    });
  }

  onMinimizeCarouselCard_(event) {
    const isEmptyRecipeIngredient = event.detail.id === TEMPORARY_ID;
    if (isEmptyRecipeIngredient) {
      const detail = { recipeId: this.recipe.id };
      this.fireGlobalEvent_(EVENTS.REMOVE_EMPTY_RECIPE_INGREDIENT, detail);

      const addButton = this.shadowRoot.querySelector('#add-button');
      addButton.classList.replace('dd_hide', 'dd_show');
    }
  }
  // END Recipe Ingredient card carousel methods
}

customElements.define('dd-editable-recipe-card-2', DdEditableRecipeCard2);
