import { SET_TOKEN } from '../actions/token.js';
import { PubSub } from '../services/pub-sub.js';

export const TOKEN_SET_EVENT = 'token-set';

const INITIAL_STATE = {
  auth: '',
  refresh: '',
  tokenData: {},
};

function parseJwt(tokenText) {
  if (!tokenText) return {};

  try {
    const base64Url = tokenText.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    console.log(e);
    return {};
  }
}

export function token(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TOKEN:
      PubSub.publish(TOKEN_SET_EVENT);
      return {
        ...state,
        auth: action.payload.authToken,
        refresh: action.payload.refreshToken,
        tokenData: action.payload.authToken ? parseJwt(action.payload.authToken) : {},
      };
    default:
      return state;
  }
}
