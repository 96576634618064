import { html, css, LitElement } from 'lit-element';
import cloneDeep from 'lodash-es/cloneDeep.js';
import isEmpty from 'lodash-es/isEmpty.js';
import { sharedStyles } from '../../theme/shared-styles.js';
import '../dd-option-slider.js';
import { PROTEIN_CATEGORY, PROTEIN_MAP } from '../../utilities/constants.js';

const MIN_TOTAL_DIET_OPTION_LIMIT = 5;
const FOOD_LIMIT_TOTAL_CHANGED_EVENT = 'food-limit-total-changed';

const DATA_MAP = {
  // slider setting by data value
  input: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
  },
  // data value by slider setting
  output: {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
  },
};

const VEGETARIAN_DATA_MAP = {
  // slider setting by data value
  input: {
    2: 0,
    3: 1,
    4: 2,
    5: 3,
  },
  // data value by slider setting
  output: {
    0: 2,
    1: 3,
    2: 4,
    3: 5,
  },
};

function getSliderValue(optionSlider) {
  const optionSliderValue = optionSlider.getCurrentValue();
  return optionSlider.dataMap ? optionSlider.dataMap.output[optionSliderValue] : optionSliderValue;
}

/**
 * protein limit sliders
 */
class DdFoodLimits extends LitElement {
  static get properties() {
    return {
      dietPlanOptions: { type: Object },
      heartHealthy: { type: Boolean }, // for setting independently of dietPlanOptions
      includeHeartHealthy: { type: Boolean, attribute: 'include-hh' }, // for setting independently of dietPlanOptions
    };
  }

  set dietPlanOptions(value) {
    const oldValue = this._dietPlanOptions;
    this._dietPlanOptions = value;
    this.requestUpdate('dietPlanOptions', oldValue);
  }

  get dietPlanOptions() {
    // LitElement rendering property requires new object to be returned
    const dietPlanOptions = cloneDeep(this._dietPlanOptions);
    this.getOptionSliderValues_(dietPlanOptions);

    return dietPlanOptions;
  }

  set heartHealthy(value) {
    if (this.includeHeartHealthy) {
      this.setHeartHealthyCompatibility_(value);

      // silently set so doesn't interfere with external set notifications
      const validTotal = this.validateTotal_();
      if (!value) {
        this.fireFoodLimitEvent_(validTotal);
      }
    }
  }

  get dietPlanOptionsDto() {
    return this.getOptionSliderValues_();
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        #hint-1 {
          margin: 16px 0;
        }

        #hint-2 {
          margin: 0 0 16px 40px;
        }

        .lede {
          /* margin: 16px 0 -10px 0;*/
        }

        .legend-container {
          width: 160px;
          margin-right: 10px;
          .hint {
            width: 31px;
            text-align: center;
          }
        }

        .legend-container > p {
          margin: 0;
        }

        #col2-legend-container {
          display: none;
        }

        #hint > .dd-icon-placeholder {
          padding: 0;
        }

        .expander-content {
          margin-bottom: 16px;
        }

        @media only screen and (min-width: 1248px) {
          #col2-legend-container {
            display: flex;
          }
        }
      `,
    ];
  }

  constructor() {
    super();
    this._dietPlanOptions = {};
  }

  render() {
    return html`
      <div class="split-section">
        <div class="split-section-child">
          <div class="dd-flex-end-container">
            <div class="dd-flex-container legend-container">
              <p class="hint text-xsmall bold">0</p>
              <p class="hint text-xsmall bold">Up to 1</p>
              <p class="hint text-xsmall bold">Up to 2</p>
              <p class="hint text-xsmall bold">Up to 3</p>
            </div>
          </div>
          ${this.getFoodLimitOptionTemplate_('redMeat', {
            isDisabled: this._dietPlanOptions.vegetarianFoods || this._dietPlanOptions.heartHealthy,
          })}
          ${this.getFoodLimitOptionTemplate_('pork', {
            isDisabled: this._dietPlanOptions.vegetarianFoods || this._dietPlanOptions.heartHealthy,
          })}
          ${this.getFoodLimitOptionTemplate_('poultry', {
            isDisabled: this._dietPlanOptions.vegetarianFoods,
          })}
        </div>
        <div class="split-section-child">
          <div id="col2-legend-container" class="dd-flex-end-container">
            <div class="dd-flex-container legend-container">
              <p class="hint text-xsmall bold">0</p>
              <p class="hint text-xsmall bold">Up to 1</p>
              <p class="hint text-xsmall bold">Up to 2</p>
              <p class="hint text-xsmall bold">Up to 3</p>
            </div>
          </div>
          ${this.getFoodLimitOptionTemplate_('fish', {})}
          ${this.getFoodLimitOptionTemplate_('shellfish', {})}
          ${this.getFoodLimitOptionTemplate_('vegetarian', {
            dataMap: this._dietPlanOptions.vegetarianFoods ? VEGETARIAN_DATA_MAP : DATA_MAP,
          })}
        </div>
      </div>
    `;
  }

  reset() {
    const optionSliders = this.shadowRoot.querySelectorAll('dd-option-slider');
    optionSliders.forEach(optionSlider => {
      optionSlider.reset();
    });
  }

  isValidTotal() {
    const total = this.getCurrentTotal_();
    const validTotal = total >= MIN_TOTAL_DIET_OPTION_LIMIT;
    return validTotal;
  }

  getFoodLimitOptionTemplate_(
    proteinKey,
    { isDisabled = false, max = 3, min = 0, dataMap = DATA_MAP },
  ) {
    const proteinMap = PROTEIN_MAP[proteinKey];
    const category = proteinMap.category;

    let value = min === max ? min : Math.min(this._dietPlanOptions[proteinKey]?.value ?? 1, max);

    if (isDisabled) {
      value = 0;
    }

    return html`
      <div id="options-container">
        <dd-option-slider
          .enabled=${!isDisabled}
          .proteinCategory=${category}
          .max=${max}
          .min=${min}
          .value=${value}
          .name=${proteinMap.name}
          .dataMap=${dataMap}
          @changed="${this.onFoodlLimitOptionChanged_}"
        ></dd-option-slider>
      </div>
    `;
  }

  /**
   * adjusts layout on initial view after rendering DOM
   */
  onShow() {
    const optionSliders = this.shadowRoot.querySelectorAll('dd-option-slider');

    optionSliders.forEach(optionSlider => {
      optionSlider.layout();
    });

    if (!isEmpty(this._dietPlanOptions)) {
      this.onFoodlLimitOptionChanged_();
    }
  }

  getCurrentTotal_() {
    const optionSliders = this.shadowRoot.querySelectorAll('dd-option-slider');

    // disregard if rendered prior to diet plan options being set
    let total = optionSliders.length ? 0 : MIN_TOTAL_DIET_OPTION_LIMIT;
    optionSliders.forEach(optionSlider => {
      // if (optionSlider.proteinCategory !== PROTEIN_CATEGORY.SHELLFISH) {
      total += getSliderValue(optionSlider);
      // }
    });

    return total;
  }

  setHeartHealthyCompatibility_(isHeartHealthy) {
    const optionSliders = this.shadowRoot.querySelectorAll('dd-option-slider');

    optionSliders.forEach(optionSlider => {
      const category = optionSlider.proteinCategory;
      if (category === PROTEIN_CATEGORY.RED_MEAT || category === PROTEIN_CATEGORY.PORK) {
        optionSlider.enabled = !isHeartHealthy;
        if (isHeartHealthy) {
          optionSlider.value = 0;
          optionSlider.reset();
        }
      }
    });
  }

  setVegetarianCompatibility_(vegetarianFoods) {
    const optionSliders = this.shadowRoot.querySelectorAll('dd-option-slider');
    optionSliders.forEach(optionSlider => {
      const category = optionSlider.proteinCategory;
      if (category === PROTEIN_CATEGORY.FISH || category === PROTEIN_CATEGORY.FISH) {
        optionSlider.enabled = !vegetarianFoods;
        if (vegetarianFoods) {
          optionSlider.value = 0;
          optionSlider.reset();
        }
      }
    });
  }

  getOptionSliderValues_(dietPlanOptions) {
    const dto = {};
    const optionSliders = this.shadowRoot.querySelectorAll('dd-option-slider');

    optionSliders.forEach(optionSlider => {
      const sliderValue = getSliderValue(optionSlider);

      switch (optionSlider.proteinCategory) {
        case PROTEIN_CATEGORY.RED_MEAT:
          if (dietPlanOptions) {
            dietPlanOptions.redMeat.value = sliderValue;
          } else {
            dto.redMeat = sliderValue;
          }
          break;
        case PROTEIN_CATEGORY.PORK:
          if (dietPlanOptions) {
            dietPlanOptions.pork.value = sliderValue;
          } else {
            dto.pork = sliderValue;
          }
          break;
        case PROTEIN_CATEGORY.POULTRY:
          if (dietPlanOptions) {
            dietPlanOptions.poultry.value = sliderValue;
          } else {
            dto.poultry = sliderValue;
          }
          break;
        case PROTEIN_CATEGORY.FISH:
          if (dietPlanOptions) {
            dietPlanOptions.fish.value = sliderValue;
          } else {
            dto.fish = sliderValue;
          }
          break;
        case PROTEIN_CATEGORY.SHELLFISH:
          if (dietPlanOptions) {
            dietPlanOptions.shellfish.value = sliderValue;
          } else {
            dto.shellfish = sliderValue;
          }
          break;
        case PROTEIN_CATEGORY.VEGETARIAN:
          if (dietPlanOptions) {
            dietPlanOptions.vegetarian.value = sliderValue;
          } else {
            dto.vegetarian = sliderValue;
          }
          break;
        default:
          break;
      }
    });

    return dto;
  }

  validateTotal_() {
    const total = this.getCurrentTotal_();
    const validTotal = total >= MIN_TOTAL_DIET_OPTION_LIMIT;

    return validTotal;
  }

  onFoodlLimitOptionChanged_() {
    const validTotal = this.validateTotal_();
    this.fireFoodLimitEvent_(validTotal);
  }

  fireFoodLimitEvent_(isValidTotal) {
    const detail = { validTotal: isValidTotal };
    const event = new CustomEvent(FOOD_LIMIT_TOTAL_CHANGED_EVENT, {
      bubbles: true,
      composed: true,
      detail,
    });
    this.dispatchEvent(event);
  }
}

customElements.define('dd-food-limits', DdFoodLimits);
