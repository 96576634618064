import { html, css, LitElement } from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-icon-button-toggle';
import '@material/mwc-formfield';
import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-select';
import { isEqual } from 'lodash-es';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './onboarding/dd-onboarding.scss';
import { isValidEmail } from '../../utilities/email-validation.js';
import { isValidPassword } from '../../utilities/password-validation.js';

const REGISTRATION_VALUES_CHANGED = 'registration-values-changed';

/**
 * Registration
 */
class DdRegistration extends LitElement {
  static get properties() {
    return {
      _values: { type: Object },
      _errors: { type: Object },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        mwc-icon {
          --mdc-icon-size: 24px;
          font-size: 24px;
          flex-grow: 0;
          margin-right: 16px;
        }

        #view-pw-toggle {
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
        }

        mwc-formfield {
          flex-grow: 1;
        }

        p.hint {
          margin: 0 0 32px 0;
          line-height: 24px;
        }

        span.hint {
          font-size: 10px;
          line-height: 12px;
        }

        .dd-line-divider {
          margin: 16px 0;
        }

        .meal-size-img {
          width: 100%;
          height: auto;
        }

        .password-field {
          position: relative;
        }

        .view-pw-toggle {
          position: absolute;
          right: 0px;
          top: 4px;
        }

        .dd-quote-text-big {
          font-size: 24px;
          line-height: 32px;
          font-style: italic;
          color: #5d5d5d;

          .dd-quote-name-big {
            font-size: 18px;
            font-weight: bold;
            color: #5d5d5d;
            display: block;
            text-align: right;
          }
        }

        @media only screen and (min-width: 1248px) {
          .dd-line-divider {
            display: none;
          }
        }
      `,
    ];
  }

  set values(value) {
    if (!isEqual(value, this._values)) {
      this._values = value;
      // this.requestUpdate();
    }
  }

  set errors(value) {
    if (!isEqual(value, this._errors)) {
      this._errors = value;
      // this.requestUpdate();
    }
  }

  get values() {
    return this._values;
  }

  get errors() {
    return this._errors;
  }

  constructor() {
    super();
    // overrides
    this._values = {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      password: '',
    };
    this._errors = {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      password: '',
    };
  }

  updated() {
    super.updated();
  }

  render() {
    return html`
        <div id="registration-wrapper">
          <h2 class="heading-margin">Almost there!</span></h2>
          <p class="hint">
            Your first personalized menu will be created in minutes!
          </p>
      <div class="split-section">
        <div class="split-section-child">
          <div class="dd-content-block">
          <mwc-textfield
            id="first-name-text-field"
              class="dd-full-width"
                outlined
              helperPersistent
              autoCapitalize="off"
                label="First Name&nbsp;&nbsp;"
                type="text"
                required
              .value=${this.values.firstName}
              .helper=${this.errors.firstName}
              @input=${e => this.updateValues_('firstName', e.target.value)}
              @blur=${() => this.validateField_('firstName')}
            >
  </mwc-textfield>
  </div>
  <div class="dd-content-block">
          <mwc-textfield
            id="last-name-text-field"
              class="dd-full-width"
							outlined
              helperPersistent
              autoCapitalize="off"
							label="Last Name&nbsp;&nbsp;"
							type="text"
							required
              .value=${this.values.lastName}
              .helper=${this.errors.lastName}
              @input=${e => this.updateValues_('lastName', e.target.value)}
              @blur=${() => this.validateField_('lastName')}
            >
  </mwc-textfield>
          </div>
          <div class="dd-content-block">
          <mwc-textfield
            id="email-text-field"
              class="dd-full-width"
							outlined
              helperPersistent
              autoCapitalize="off"
							label="Email address &nbsp;&nbsp;"
							type="email"
							required
              .value=${this.values.email}
              .helper=${this.errors.email}
              @input=${e => this.updateValues_('email', e.target.value)}
              @blur=${() => this.validateField_('email')}
            ></mwc-textfield>
          </div>
          <div class="dd-content-block">
          <mwc-textfield
            id="confirm-email-text-field"
              class="dd-full-width"
							outlined
              helperPersistent
              autoCapitalize="off"
							label="Confirm email address &nbsp;&nbsp;"
							type="email"
							required
              .value=${this.values.confirmEmail}
              .helper=${this.errors.confirmEmail}
              @input=${e => this.updateValues_('confirmEmail', e.target.value)}
              @blur=${() => this.validateField_('confirmEmail')}
            ></mwc-textfield>
          </div>
          <div class="dd-content-block">
            <div class="password-field">
          <mwc-textfield
             id="password-text-field"
              class="dd-full-width"
							outlined
              helperPersistent
              autoCapitalize="off"
							label="Password&nbsp;&nbsp;"
							type="password"
							required
              .value=${this.values.password}
              .helper=${this.errors.password}
              @input=${e => this.updateValues_('password', e.target.value)}
              @blur=${() => this.validateField_('password')}
            ></mwc-textfield>
            <mwc-icon-button-toggle
								class="view-pw-toggle"
                slot="trailing-icon"
								onIcon="visibility_off"
								offIcon="visibility"
								@click="${this.onViewPwToggle_}"></mwc-icon-button-toggle>
           </div>
          </div>
          <p class="dd-caption hint">
            Your email will be your user ID.
          </p>
        </div>
        <div class="split-section-child">
          <div class="dd-flex-container">
            <img id="logo" src="images/onboarding/register.png" class="meal-size-img" />
          </div>
        </div>
      </div>
        </div>
    `;
  }

  onViewPwToggle_(event) {
    const actionView = event.target.on;
    const passwordTextField = this.shadowRoot.querySelector('#password-text-field');
    passwordTextField.type = actionView ? 'text' : 'password';
  }

  onKeypress_(event) {
    if (event.key === 'Enter') {
      this.updateValues_();
    }
  }

  updateValues_(field, value) {
    this.values = {
      ...this.values,
      [field]: value,
    };

    this.onValuesChanged_();
  }

  setFieldError_(field, errorMessage) {
    this.errors = {
      ...this.errors,
      [field]: errorMessage,
    };
  }

  clearFieldError_(field) {
    this.errors = {
      ...this.errors,
      [field]: '',
    };
  }

  validateField_(field) {
    switch (field) {
      case 'firstName':
        this.validateFirstName_();
        break;
      case 'lastName':
        this.validateLastName_();
        break;
      case 'email':
        this.validateEmail_();
        break;
      case 'confirmEmail':
        this.validateConfirmEmail_();
        break;
      case 'password':
        this.validatePassword_();
        break;
      default:
        break;
    }
  }

  validateForm_() {
    this.validateFirstName_();
    this.validateLastName_();
    this.validateEmail_();
    this.validateConfirmEmail_();
    this.validatePassword_();

    const hasError = Object.keys(this.values).some(field => !!this.errors[field]);

    return !hasError;
  }

  validateFirstName_() {
    if (!this.values.firstName) {
      this.setFieldError_('firstName', 'First name is required.');
    } else {
      this.clearFieldError_('firstName');
    }
  }

  validateLastName_() {
    if (!this.values.lastName) {
      this.setFieldError_('lastName', 'Last name is required.');
    } else {
      this.clearFieldError_('lastName');
    }
  }

  validateEmail_() {
    if (!isValidEmail(this.values.email)) {
      this.setFieldError_('email', 'Invalid email format.');
    } else {
      this.clearFieldError_('email');
    }
  }

  validateConfirmEmail_() {
    if (this.values.email !== this.values.confirmEmail) {
      this.setFieldError_('confirmEmail', 'Emails do not match.');
    } else {
      this.clearFieldError_('confirmEmail');
    }
  }

  validatePassword_() {
    if (!isValidPassword(this.values.password)) {
      this.setFieldError_(
        'password',
        'Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.',
      );
    } else {
      this.clearFieldError_('password');
    }
  }

  onValuesChanged_() {
    // TDD-38 Should update registration values even if not valid to update Next button's visibility
    // if (this.validateForm_()) {
    const newEvent = new CustomEvent(REGISTRATION_VALUES_CHANGED, {
      bubbles: true,
      composed: true,
      detail: {
        ...this.values,
        validFormValues: this.validateForm_(),
      },
    });
    this.dispatchEvent(newEvent);
    // }
  }
}

customElements.define('dd-registration', DdRegistration);
