import { html, css, LitElement } from 'lit-element';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './onboarding/dd-onboarding.scss';
import '../dd-iframe.js';
import { SubscriptionPlans } from '../../resources/subscription-plans.js';

import { store } from '../../state/store.js';
import { openSubscriptionCheckout } from '../../actions/api/subscription-api';

const SUBSCRIPTION_CREATED = 'subscription-created';

/**
 * subscription submission
 */
class DdSubscriptionSubmit extends LitElement {
  static get properties() {
    return {
      chargebeeInstance: { type: Object },
      planId: { type: String },
      userId: { type: String },
    };
  }

  constructor() {
    super();
    this.chargebeeInstance = {};
  }

  async createCheckout(checkoutData) {
    const originalElementToMount = this.shadowRoot.getElementById('checkout');
    const newElementToMount = document.createElement('DIV');
    newElementToMount.setAttribute('id', 'checkout');

    originalElementToMount.parentNode.insertBefore(newElementToMount, originalElementToMount);
    originalElementToMount.remove();

    try {
      // Create the checkout
      const checkout = await this.chargebeeInstance.createCheckout({
        url: checkoutData.url,
        callbacks: {
          loaded: () => {
            console.log('Checkout opened');
            const container = this.shadowRoot.getElementById('checkout');
            const iframe = container.querySelector('iframe');
            iframe.style.display = 'block';
            iframe.style.width = '100%';
            iframe.style.height = '500px';
            const loader = container.querySelector('#cb-checkout-loader');
            loader.style.display = 'none';
          },
          error: error => {
            console.error('Error during checkout:', error);
          },
          close: () => {
            console.log('Checkout closed');
          },
          success: data => {
            console.log('Checkout success. ID:', data);
            this.fireSubscriptionCreated_();
          },
          step: value => {
            console.log('Checkout step:', value);
          },
        },
      });

      // Mount checkout on the container
      await checkout.mount(newElementToMount);
      console.log('Mounted');
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  fireSubscriptionCreated_() {
    this.dispatchEvent(new CustomEvent(SUBSCRIPTION_CREATED, {}));
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        p.hint {
          margin: 0 0 32px 0;
          line-height: 24px;
        }
      `,
    ];
  }

  render() {
    if (!this.chargebeeInstance || !this.planId || !this.userId) {
      return null;
    }

    const subscriptionPlanLink = SubscriptionPlans[this.planId]?.link || '';
    const url = `${subscriptionPlanLink}&custom_fields[user_id]=${this.userId}`;

    setTimeout(() => {
      store.dispatch(
        openSubscriptionCheckout(async data => {
          this.createCheckout(await data.hostedPage());
        }, this.planId),
      );
    }, 100);

    return html`
      <div id="wrapper">
        <h2>
          Last step!
        </h2>
        <p class=" bold">
          You will be charged nothing during the trial, and you can cancel anytime.
        </p>

        <!-- This is super hacky, but adding this iframe here is the only way to fix a bug with Chargebee error messages not displaying -->
        <iframe width="1px" height="1px" style="border: none;display: inline;" src=${url}></iframe>

        <div id="checkout">
          <!-- Chargebee will embed checkout form here -->
        </div>
      </div>
    `;
  }
}

customElements.define('dd-subscription-submit', DdSubscriptionSubmit);
