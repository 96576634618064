import { html, css, LitElement } from 'lit-element';
import '@material/mwc-formfield';
import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-select';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './onboarding/dd-onboarding.scss';
import { FAMILY_SIZE } from '../../utilities/constants.js';

const FAMILY_SIZE_CHANGED_EVENT = 'family-size-changed';

/**
 * Meal size selector
 */
class DdMealSizeSelect extends LitElement {
  get getMealSize() {
    return this.shadowRoot.querySelector('#option-simple-family-size-half').checked
      ? FAMILY_SIZE.half.value.toString()
      : FAMILY_SIZE.full.value.toString();
  }

  static get properties() {
    return {
      familySize: { type: Number },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        mwc-icon {
          --mdc-icon-size: 24px;
          font-size: 24px;
          flex-grow: 0;
          margin-right: 16px;
        }

        mwc-formfield {
          flex-grow: 1;
        }

        p.hint {
          margin: 0 0 32px 0;
          line-height: 24px;
        }

        span.hint {
          font-size: 10px;
          line-height: 12px;
        }

        .dd-line-divider {
          margin: 16px 0;
        }

        .meal-size-img {
          width: 100%;
          height: auto;
        }

        @media only screen and (min-width: 1248px) {
          .dd-line-divider {
            display: none;
          }
        }
      `,
    ];
  }

  render() {
    return html`
      <div id="family-size-wrapper" class="split-section">
        <div class="split-section-child">
          <h2 class="heading-margin">Select your <span class="dd-h2 bold">Meal Size</span></h2>
          <p class="hint">
            Your menu will be created based on the number of servings below. You can change this
            anytime.
          </p>
          <div class="dd-flex-container">
            <mwc-icon class="dd-purple-fg">group</mwc-icon>
            <mwc-formfield alignEnd spaceBetween label="Serves ${FAMILY_SIZE.half.description}">
              <mwc-radio
                name="family-size"
                id="option-simple-family-size-half"
                ?checked=${this.familySize === FAMILY_SIZE.half.value}
                .value=${FAMILY_SIZE.half.value.toString()}
                @change="${this.onFamilySizeChange_}"
              ></mwc-radio>
            </mwc-formfield>
          </div>
          <div class="dd-flex-container">
            <mwc-icon class="dd-purple-fg">groups</mwc-icon>
            <mwc-formfield alignEnd spaceBetween label="Serves ${FAMILY_SIZE.full.description}">
              <mwc-radio
                name="family-size"
                id="option-simple-family-size-full"
                ?checked=${this.familySize === FAMILY_SIZE.full.value}
                .value=${FAMILY_SIZE.full.value.toString()}
                @change="${this.onFamilySizeChange_}"
              ></mwc-radio>
            </mwc-formfield>
          </div>
        </div>
        <div class="split-section-child">
          <div class="dd-flex-container">
            <img id="logo" src="images/onboarding/family-size.png" class="meal-size-img" />
          </div>
        </div>
      </div>
    `;
  }

  onFamilySizeChange_(event) {
    const newEvent = new CustomEvent(FAMILY_SIZE_CHANGED_EVENT, {
      bubbles: true,
      composed: true,
      detail: {
        familySize: event.target.value,
        isSilent: true,
      },
    });
    this.dispatchEvent(newEvent);
  }
}

customElements.define('dd-meal-size-select', DdMealSizeSelect);
