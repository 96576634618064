import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
import { reduxBatch } from '@manaflair/redux-batch';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {
  createBrowserHistory,
  routerReducer,
  routerMiddleware,
  startListener,
} from 'redux-first-routing';
import LogRocket from 'logrocket';

import { apiReducer } from '../reducers/api.js';
import { appFlags } from '../reducers/app-flags.js';
import { dietPlanOptions } from '../reducers/diet-plan-options.js';
import { favorites } from '../reducers/favorites.js';
import { features } from '../reducers/features.js';
import { fulfillment } from '../reducers/fulfillment.js';
import { ingredients } from '../reducers/ingredients.js';
import { menu } from '../reducers/menu.js';
import { navigation } from '../reducers/navigation.js';
import { news } from '../reducers/news.js';
import { personalRecipes } from '../reducers/personal-recipes.js';
import { profile } from '../reducers/profile';
import { recipeShopItems } from '../reducers/recipe-shop-items.js';
import { search } from '../reducers/search.js';
import { shoppingList } from '../reducers/shopping-list.js';
import { subscription } from '../reducers/subscription.js';
import { token } from '../reducers/token.js';
import { uiSettings } from '../reducers/ui-settings.js';
import requestMiddleware from './middleware/request-middleware.js';
import viewTransform from './transforms/view-transform.js';
// import { logger } from './middleware/logger.js';
import { readAppFlags, saveAppFlags } from '../listeners/appFlagListener.js';
import { TokenListener } from '../listeners/tokenListener.js';
import { observeStore } from '../utilities/observe-store.js';

const browserHistory = createBrowserHistory();
const routerMid = routerMiddleware(browserHistory);

// Reducers adding substates to the store
const rootReducer = combineReducers({
  api: apiReducer,
  appFlags,
  dietPlanOptions,
  favorites,
  features,
  fulfillment,
  ingredients,
  menu,
  navigation,
  news,
  personalRecipes,
  profile,
  recipeShopItems,
  search,
  shoppingList,
  subscription,
  token,
  uiSettings,
  router: routerReducer, // Convention is to use the "router" property
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['api', 'appFlags', 'router', 'search', 'token'],
  stateReconciler: autoMergeLevel2,
  transforms: [viewTransform],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/** Initializes the Redux store with
 *	middleware
 *		logger - console logger for redux changes (redundant)
 *		requestMiddleware - handles API token refresh
 *		redux-thunk - dispatch async actions
 *		routerMid - synthetic URL routing
 *	enhancer
 *		reduxBatch (pre and post enhance) - combine dispatches into single change
 *
 *	ref: https://github.com/Polymer/pwa-starter-kit/wiki/4.-Redux-and-state-management
 */
export const store = createStore(
  persistedReducer,
  {
    appFlags: readAppFlags(),
  },
  devCompose(
    reduxBatch,
    applyMiddleware(
      requestMiddleware(),
      thunk,
      routerMid,
      LogRocket.reduxMiddleware({
        stateSanitizer: state => ({
          ...state,
          token: undefined,
        }),
      }),
    ),
    reduxBatch,
  ),
);

export const persistor = persistStore(store);

// configure listeners
startListener(browserHistory, store);

const tokenListener = new TokenListener(store);
tokenListener.subscribeHandler();
const tokenCallback = tokenListener.subscribeHandler.bind(tokenListener);
store.subscribe(tokenCallback);

const appFlagSelect = state => state.appFlags;
const onAppFlagChange = appFlagState => {
  saveAppFlags(appFlagState);
};
observeStore(store, appFlagSelect, onAppFlagChange);
