/**
 * API actions for day-menus resource
 * ref: https://github.com/redux-utilities/flux-standard-action
 *      /state/middleware/request-middleware.js
 *      https://docs.google.com/spreadsheets/d/1MmffvNk7OJFhEYA2ZSUOVZ6OT_um1848_o-EpzCv1MA/edit?usp=sharing
 */
import {
  axios,
  getApiUrl,
  getParameters,
  API_REQUEST,
  startRequest,
  completeRequest,
  NOK_RESPONSE_HINT,
} from './api-base.js';
import { saveWeekMenu } from '../menu.js';
import { saveSearch } from '../search.js';
import { INITIAL_STATE } from '../../reducers/search.js';
import { MAIN_DISH, FIRST_SIDE, SECOND_SIDE } from '../../components/dd-card/dd-day-card.js';

// REST resources
const DAY_MENU_RESOURCE = 'day-menus';
const MAIN_ENDPOINT = 'main';
const FIRST_SIDE_ENDPOINT = 'first-side';
const SECOND_SIDE_ENDPOINT = 'second-side';
const ENDPOINT_MAP = {
  [MAIN_DISH]: MAIN_ENDPOINT,
  [FIRST_SIDE]: FIRST_SIDE_ENDPOINT,
  [SECOND_SIDE]: SECOND_SIDE_ENDPOINT,
};
Object.freeze(ENDPOINT_MAP);

/**
 * add recipe in location from dayMenuId
 * @param object selector { location, dayMenuId, recipeId }
 * @return function action to monitor with requestMiddleware: { payload: async function }
 */
export function requestAddRecipe(previousWeek, selector) {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest());

      const recipesEndpoint = `${DAY_MENU_RESOURCE}/${selector.dayMenuId}/recipes/${
        ENDPOINT_MAP[selector.location]
      }`;
      const url = getApiUrl(recipesEndpoint);

      return axios
        .post(
          url,
          { recipeId: selector.recipeId },
          {
            params: getParameters(previousWeek),
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then(response => {
          dispatch([saveWeekMenu(response.data), completeRequest(), saveSearch(INITIAL_STATE)]);
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}

/**
 * replace recipe in location from dayMenuId
 * @param object selector { location, dayMenuId, recipeId }
 * @return function action to monitor with requestMiddleware: { payload: async function }
 */
export function requestReplaceRecipe(previousWeek, selector) {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest());

      const recipesEndpoint = `${DAY_MENU_RESOURCE}/${selector.dayMenuId}/recipes/${
        ENDPOINT_MAP[selector.location]
      }`;
      const url = getApiUrl(recipesEndpoint);

      return axios
        .put(
          url,
          { recipeId: selector.recipeId },
          {
            params: getParameters(previousWeek),
            headers: { Authorization: `Bearer ${token}` },
          },
        )
        .then(response => {
          dispatch([saveWeekMenu(response.data), completeRequest(), saveSearch(INITIAL_STATE)]);
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}

/**
 * remove recipe in location from dayMenuId
 * @param object selector { location, dayMenuId }
 * @return function action to monitor with requestMiddleware: { payload: async function }
 */
export function requestRemoveRecipe(previousWeek, selector) {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest());

      const recipesEndpoint = `${DAY_MENU_RESOURCE}/${selector.dayMenuId}/recipes/${
        ENDPOINT_MAP[selector.location]
      }`;
      const url = getApiUrl(recipesEndpoint);

      return axios
        .delete(url, {
          params: getParameters(previousWeek),
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          dispatch([saveWeekMenu(response.data), completeRequest()]);
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}

/**
 * remove all recipes from dayMenuId
 * @return function action to monitor with requestMiddleware: { payload: async function }
 */
export function requestRemoveRecipes(previousWeek, dayMenuId) {
  return {
    type: API_REQUEST,
    payload(dispatch, token) {
      dispatch(startRequest('Success! Recipe Removed'));

      const recipesEndpoint = `${DAY_MENU_RESOURCE}/${dayMenuId}/recipes`;
      const url = getApiUrl(recipesEndpoint);

      return axios
        .delete(url, {
          params: getParameters(previousWeek),
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          dispatch([saveWeekMenu(response.data), completeRequest()]);
        })
        .catch(error => {
          dispatch(completeRequest(NOK_RESPONSE_HINT));
          console.log(error);
        });
    },
  };
}
