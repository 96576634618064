import { html, css, LitElement } from 'lit-element';
import { sharedStyles } from '../theme/shared-styles.js';
import './dd-expandable-content.js';
import './dd-meal-select-option.js';
import './dd-select-option.js';

export const SORT_SWITCH_ID = 'sortSwitchId';

const SORT_SWITCH_CONFIG = {
  id: SORT_SWITCH_ID,
  name: 'Group similar items in my list',
};
Object.freeze(SORT_SWITCH_CONFIG);

/**
 * a DdExpandableContent with contents consisting of DdSelectOptions
 */
class DdItemSelector extends LitElement {
  static get properties() {
    return {
      isOpen: { type: Boolean },
      options: { type: Object },
      storeName: { type: String },
      sortEnabled: { type: Boolean },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      css`
        #title-box {
          padding: 8px 0 0 0;
          text-transform: capitalize;
        }

        #content-box {
          padding: 8px 0;
        }

        #content-spacer {
          margin: 16px 0;
        }

        .option-box {
          padding: 4px 0;
        }
      `,
    ];
  }

  render() {
    return html`
      <div>
        <div id="title-box" slot="title">
          <h2>I'm</h2>
          <h1>shopping for:</h1>
        </div>
        <div id="content-box" slot="content">
          ${this.createOptionTemplates_()}
          <div id="content-spacer" class="dd-line-divider dd_hide"></div>
          <div class="dd-dense-content-item dd_hide">
            <dd-select-option
              .isSwitch=${true}
              .rtl=${true}
              .option=${SORT_SWITCH_CONFIG}
              .checked=${this.sortEnabled}
            ></dd-select-option>
          </div>
        </div>
      </div>
    `;
  }

  createOptionTemplates_() {
    if (this.options) {
      const optionTemplates = this.options.map(
        option => html`
          <div class="option-box">
            <dd-meal-select-option
              .isSwitch=${false}
              .rtl=${true}
              .option=${option}
              .enabled=${true}
              .checked=${option.active}
            >
            </dd-meal-select-option>
          </div>
        `,
      );

      return optionTemplates;
    }

    return '';
  }

  getActive() {
    const selectOptions = Array.from(this.shadowRoot.querySelectorAll('dd-meal-select-option'));
    const activeOptions = selectOptions.filter(
      selectOption =>
        selectOption.active && selectOption.option.id && typeof selectOption.option.id === 'number',
    );

    return activeOptions.map(activeOption => activeOption.option);
  }
}

customElements.define('dd-item-selector', DdItemSelector);
