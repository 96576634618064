import { html, css, LitElement } from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-icon-button-toggle';
import '@material/mwc-formfield';
import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-select';
import { isEqual } from 'lodash-es';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './onboarding/dd-onboarding.scss';
import { isValidEmail } from '../../utilities/email-validation.js';

import { store } from '../../state/store.js';
import { LOGIN_VIEW, navigate } from '../../actions/navigation';

const FORGOT_PASSWORD_SUBMIT = 'forgot-password-submit';

/**
 * Forgot password
 */
class DdForgotPassword extends LitElement {
  static get properties() {
    return {
      _value: { type: String },
      _error: { type: String },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        :host {
          display: flex;
          flex-flow: column;
          width: 100%;
          align-items: center;
          background-color: var(--mdc-theme-surface);
        }

        .dd-login {
          max-width: 500px;
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          align-items: center;
        }

        #logo {
          margin: 56px;
        }

        @media only screen and (min-width: 1248px) {
          .dd-line-divider {
            display: none;
          }
        }
      `,
    ];
  }

  set value(value) {
    if (!isEqual(value, this._value)) {
      this._value = value;
      // this.requestUpdate();
    }
  }

  set error(value) {
    if (!isEqual(value, this._error)) {
      this._error = value;
      // this.requestUpdate();
    }
  }

  get value() {
    return this._value;
  }

  get error() {
    return this._error;
  }

  constructor() {
    super();
    // overrides
    this._value = '';
    this._error = '';
  }

  updated() {
    super.updated();
  }

  render() {
    return html`
      <div class="dd-login">
        <img id="logo" src="images/logo-sm.png" />
        <mwc-textfield
          id="email-text-field"
          outlined
          helperPersistent
          autocapitalize="off"
          label="Email address&nbsp;&nbsp;"
          type="email"
          required
          .value=${this.value}
          .helper=${this.error}
          @input=${e => this.updateValue_(e.target.value)}
          @blur=${() => this.validateField_()}
        >
        </mwc-textfield>

        <mwc-button
            outlined
            class="nav-button dark-filled"
            label="Restore password"
            title="Restore password"
            @click="${this.onSubmit_}"
        ></mwc-button>


        <mwc-button
            outlined
            class="nav-button"
            label="Back"
            title="Back"
            @click="${this.onBack_}"
        ></mwc-button>
        </div>
      </div>
    `;
  }

  updateValue_(value) {
    this.value = value;
  }

  setFieldError_(errorMessage) {
    this.error = errorMessage;
  }

  clearFieldError_() {
    this.error = '';
  }

  validateField_() {
    this.validateEmail_();
  }

  validateForm_() {
    this.validateEmail_();

    const hasError = this.error;

    return !hasError;
  }

  validateEmail_() {
    if (!isValidEmail(this.value)) {
      this.setFieldError_('Invalid email format.');
    } else {
      this.clearFieldError_();
    }
  }

  onSubmit_() {
    if (this.validateForm_()) {
      const newEvent = new CustomEvent(FORGOT_PASSWORD_SUBMIT, {
        bubbles: true,
        composed: true,
        detail: {
          email: this.value,
          validFormValues: this.validateForm_(),
        },
      });
      this.dispatchEvent(newEvent);
    }
  }

  /* eslint class-methods-use-this: "off" */
  onBack_() {
    store.dispatch(navigate(LOGIN_VIEW));
  }
}

customElements.define('dd-forgot-password', DdForgotPassword);
