import { html } from 'lit-element';
import '@material/mwc-icon-button';
import { sharedStyles } from '../../theme/shared-styles.js';
import { SALES_ICON, SALES_FG } from '../../theme/color-classes.js';
import style from './dd-subscription-portal.scss';
import { PageViewElement } from '../page-view-element.js';
import { getIconTemplate } from '../template-helpers.js';
import './dd-card/dd-plan-option-card.js';
import { SubscriptionPlans, PLAN_1, PLAN_2 } from '../../resources/subscription-plans.js';

const CLOSE_EVENT = 'close';

const QUOTE_1 =
  'This has made such a difference in our routines.  So glad we found you!  We’ve been recommending the service to all our friends.';
const AUTHOR_1 = 'Jena';
const QUOTE_2 =
  'I cannot say enough good things about this. The recipes are amazing and my grocery bill has gone down - just like it was advertised.';
const AUTHOR_2 = 'Elizabeth';

const STRESS_LABEL =
  'No more “What’s for Dinner” stress with personalized menus created just for you';
const SAVINGS_LABEL = 'Save money with menus based on your store’s sales';
const NUTRITION_LABEL = 'Eat better with our focus on healthy, whole ingredients';
const TIME_LABEL =
  'Save hours of time every week with easy to prepare meals and organized shopping lists. NO planning time required';
const BONUS_LABEL = 'Enjoy extras! Dinner party plans, make ahead menus and coupons';

function getOptionCardsTemplate(selectedPlan) {
  return html`
    <div class="dd-host-centered option-cards-container">
      <dd-plan-option-card
        .selected=${selectedPlan === SubscriptionPlans[PLAN_1].id}
        .promoted=${false}
        .option=${SubscriptionPlans[PLAN_1]}
      ></dd-plan-option-card>
      <dd-plan-option-card
        .selected=${selectedPlan === SubscriptionPlans[PLAN_2].id || selectedPlan === ''}
        .promoted=${true}
        .option=${SubscriptionPlans[PLAN_2]}
      ></dd-plan-option-card>
    </div>
  `;
}

function getIconLabelTemplate(iconName, colorClassName, label, iconClassName = 'material-icons') {
  return html`
    <div class="dd-flex-start-container">
      ${getIconTemplate(iconName, true, '', '', colorClassName, iconClassName)}
      <h5 class="dd-pad-left icon-label">${label}</h5>
    </div>
  `;
}

function getQuoteSectionTemplate(text, author) {
  const quoteImagePath = 'images/quote.png';

  return html`
    <div class="quote-section dd-flex-start-container split-section-child">
      <img src="/${quoteImagePath}" />
      <p class="dd-quote-text">
        ${text}
        <br />
        <span class="dd-quote-name">${author}</span>
      </p>
    </div>
  `;
}

/**
 * free trial expiry screen
 */
class DdSubscriptionPortal extends PageViewElement {
  static get properties() {
    return {
      heading: { type: String },
      isFreeTrial: { type: Boolean },
      isNoneSubscription: { type: Boolean },
      selectedPlan: { type: String },
    };
  }

  static get styles() {
    return [sharedStyles, style];
  }

  render() {
    return html`
      <div id="grid-outer" class="dd-full-screen">
        <div id="grid-middle" class="dd-full-screen">
          <div id="grid-inner">
            ${this.isFreeTrial
              ? html`
                  <div class="dd-flex-end-container">
                    <mwc-icon-button icon="close" @click="${this.onClose_}"></mwc-icon-button>
                  </div>
                `
              : ''}
            <div class="content-container">
              ${this.isFreeTrial || this.isNoneSubscription
                ? html`
                    <h1 class="heading-margin">
                      ${this.isNoneSubscription ? 'Subscribe Now' : 'Upgrade Now'}
                    </h1>
                  `
                : html`
                    <h2>Oh no! Your Dinner Daily <span class="dd-h2 bold">Free Trial</span></h2>
                    <h1 class="heading-margin">Has Expired!</h1>
                  `}
              <p>Keep receiving your personalized weekly menus by selecting a membership plan</p>
            </div>
            ${getOptionCardsTemplate(this.selectedPlan)}

            <div class="content-container">
              <h2>Why The Dinner Daily?</h2>
              <div id="list-container" class="split-section">
                <div class="split-section-child">
                  ${getIconLabelTemplate('check_circle', 'dd-primary-dark-fg', STRESS_LABEL)}
                  ${getIconLabelTemplate('', SALES_FG, SAVINGS_LABEL, SALES_ICON)}
                  ${getIconLabelTemplate('restaurant', 'dd-other-fg', NUTRITION_LABEL)}
                </div>
                <div class="split-section-child">
                  ${getIconLabelTemplate('schedule', 'dd-frozen-fg', TIME_LABEL)}
                  ${getIconLabelTemplate('add_circle_outline', 'dd-secondary-fg', BONUS_LABEL)}
                </div>
              </div>
              <h2>Our members say it best!</h2>
              <div id="quote-container" class="split-section">
                ${getQuoteSectionTemplate(QUOTE_1, AUTHOR_1)}
                <div class="dd-line-divider"></div>
                ${getQuoteSectionTemplate(QUOTE_2, AUTHOR_2)}
              </div>
            </div>

            <div class="content-container">
              <span class="fine-print"
                >Your first 14 days are completely free. After 14 days, you will be charged for your
                membership term per the above pricing. Your membership will automatically renew
                until canceled. You can cancel anytime from your Account Settings.</span
              >
            </div>
          </div>
        </div>
      </div>
    `;
  }

  onClose_() {
    const event = new CustomEvent(CLOSE_EVENT, {
      detail: {},
    });
    this.dispatchEvent(event);
  }

  onOptionClick_() {
    const optionCards = this.shadowRoot.querySelectorAll('dd-plan-option-card');
    optionCards.forEach(card => {
      card.disabled = true;
    });
  }
}

customElements.define('dd-subscription-portal', DdSubscriptionPortal);
