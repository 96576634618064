import { html, css, LitElement } from 'lit-element';
import '@material/mwc-textfield';
import '@material/mwc-icon-button-toggle';
import '@material/mwc-formfield';
import '@material/mwc-icon';
import '@material/mwc-list/mwc-list-item';
import '@material/mwc-radio';
import '@material/mwc-select';
import { isEqual } from 'lodash-es';
import { sharedStyles } from '../../theme/shared-styles.js';
import style from './onboarding/dd-onboarding.scss';
import { isValidPassword } from '../../utilities/password-validation';
import { store } from '../../state/store';
import { LOGIN_VIEW, navigate } from '../../actions/navigation';

const RESEAT_FORGOT_PASSWORD_SUBMIT = 'reset-forgot-password-submit';

/**
 * Forgot password
 */
class DdResetForgotPassword extends LitElement {
  static get properties() {
    return {
      _values: { type: Object },
      _errors: { type: Object },
    };
  }

  static get styles() {
    return [
      sharedStyles,
      style,
      css`
        :host {
          display: flex;
          flex-flow: column;
          width: 100%;
          align-items: center;
          background-color: var(--mdc-theme-surface);
        }

        .dd-login {
          max-width: 500px;
          padding: 16px;
          display: flex;
          flex-direction: column;
          gap: 24px;
          align-items: center;
        }

        #logo {
          margin: 56px;
        }

        .password-field {
          width: 200px;
        }

        @media only screen and (min-width: 1248px) {
          .dd-line-divider {
            display: none;
          }
        }
      `,
    ];
  }

  set values(value) {
    if (!isEqual(value, this._values)) {
      this._values = value;
      // this.requestUpdate();
    }
  }

  set errors(value) {
    if (!isEqual(value, this._errors)) {
      this._errors = value;
      // this.requestUpdate();
    }
  }

  get values() {
    return this._values;
  }

  get errors() {
    return this._errors;
  }

  constructor() {
    super();
    // overrides

    // overrides
    this._values = {
      password: '',
      repeat: '',
    };
    this._errors = {
      password: '',
      repeat: '',
    };
  }

  updated() {
    super.updated();
  }

  render() {
    return html`
      <div class="dd-login">
        <img id="logo" src="images/logo-sm.png" />
        
        <mwc-textfield
            class="password-field"
            outlined
            label="New Password&nbsp;&nbsp;"
            type="password"
            required
            autoCapitalize="off"
            .value=${this.values.password}
            .helper=${this.errors.password}
            @input=${e => {
              this.updateValues_('password', e.target.value);
              this.validateField_('password');
            }}
        ></mwc-textfield>

        <mwc-textfield
            class="password-field"
            outlined
            label="Repeat Password&nbsp;&nbsp;"
            type="password"
            required
            autoCapitalize="off"
            .value=${this.values.repeat}
            .helper=${this.errors.repeat}
            @input=${e => {
              this.updateValues_('repeat', e.target.value);
              this.validateField_('repeat');
            }}
        ></mwc-textfield>

        <mwc-button
            outlined
            class="nav-button dark-filled"
            label="Change password"
            title="Change password"
            @click="${this.onSubmit_}"
            ?disabled="${!this.validateForm_()}"
        ></mwc-button>

        <mwc-button
            outlined
            class="nav-button"
            label="Back"
            title="Back"
            @click="${this.onBack_}"
        ></mwc-button>
        </div>
      </div>
    `;
  }

  updateValues_(field, value) {
    this.values = {
      ...this.values,
      [field]: value,
    };
  }

  setFieldError_(field, errorMessage) {
    this.errors = {
      ...this.errors,
      [field]: errorMessage,
    };
  }

  clearFieldError_(field) {
    this.errors = {
      ...this.errors,
      [field]: undefined,
    };
  }

  validateField_(field) {
    switch (field) {
      case 'password':
        this.validatePassword_();
        break;
      case 'repeat':
        this.validateRepeatPassword_();
        break;
      default:
        break;
    }
  }

  validateForm_() {
    const hasError = Object.keys(this.values).some(field => !!this.errors[field]);

    return !hasError;
  }

  validatePassword_() {
    if (!isValidPassword(this.values.password)) {
      this.setFieldError_(
        'password',
        'Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.',
      );
    } else {
      this.clearFieldError_('password');
    }
  }

  validateRepeatPassword_() {
    if (this.values.password !== this.values.repeat) {
      this.setFieldError_('repeat', 'Passwords do not match.');
    } else {
      this.clearFieldError_('repeat');
    }
  }

  onSubmit_() {
    if (this.validateForm_()) {
      const newEvent = new CustomEvent(RESEAT_FORGOT_PASSWORD_SUBMIT, {
        detail: {
          password: this.values.password,
        },
      });
      this.dispatchEvent(newEvent);
    }
  }

  /* eslint class-methods-use-this: "off" */
  onBack_() {
    window.history.replaceState(null, document.title, '/');
    store.dispatch(navigate(LOGIN_VIEW));
  }
}

customElements.define('dd-reset-forgot-password', DdResetForgotPassword);
