export const modifySnackbarSurface = (snackbar, showIcon = true) => {
  if (!snackbar) {
    return snackbar;
  }

  const surface = snackbar.shadowRoot.querySelector('.mdc-snackbar__surface');

  if (surface) {
    if (showIcon) {
      // create icon
      const icon = document.createElement('mwc-icon');
      // icon.slot = 'graphic';
      icon.innerText = 'restaurant_menu';
      icon.style.color = 'white';
      icon.style.marginLeft = '10px';

      // remove existing icon
      const existingIcon = surface.querySelector('mwc-icon');
      if (existingIcon) {
        surface.removeChild(existingIcon);
      }
      surface.insertBefore(icon, surface.firstChild);
    }

    // set background color and insert icon
    surface.style.backgroundColor = '#56BAB4';
    surface.style.display = 'flex';
    surface.style.alignItems = 'scenter';
  }

  return snackbar;
};
