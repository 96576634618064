export const StatesStores = {
  ME: [
    {
      internalName: 'Market Basket',
    },
    {
      internalName: 'Hannaford',
    },
    {
      internalName: 'Shaws and Star Market',
      alias: "Shaw's and Star Market",
    },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Walmart',
    },
  ],
  MA: [
    {
      internalName: 'Market Basket',
    },
    {
      internalName: 'Stop & Shop',
    },
    {
      internalName: 'Hannaford',
    },
    {
      internalName: 'Shaws and Star Market',
      alias: "Shaw's and Star Market",
    },
    // {
    //   internalName: 'Roche Bros',
    // },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Big Y',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Price Chopper',
    },
    {
      internalName: 'Wegmans',
    },
    {
      internalName: 'Walmart',
    },
  ],
  NH: [
    {
      internalName: 'Market Basket',
    },
    {
      internalName: 'Stop & Shop',
    },
    {
      internalName: 'Hannaford',
    },
    {
      internalName: 'Shaws and Star Market',
      alias: "Shaw's and Star Market",
    },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Price Chopper',
    },
    {
      internalName: 'Walmart',
    },
  ],
  RI: [
    {
      internalName: 'Market Basket',
    },
    {
      internalName: 'Stop & Shop',
    },
    {
      internalName: 'Shaws and Star Market',
      alias: "Shaw's and Star Market",
    },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Walmart',
    },
  ],
  CT: [
    {
      internalName: 'Stop & Shop',
    },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Big Y',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Price Chopper',
    },
    {
      internalName: 'ShopRite Sun-Sat',
      alias: 'ShopRite',
    },
    {
      internalName: 'Walmart',
    },
  ],
  NJ: [
    {
      internalName: 'Stop & Shop',
    },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'ShopRite Sun-Sat',
      alias: 'ShopRite',
    },
    {
      internalName: 'Wegmans',
    },
    {
      internalName: 'Walmart',
    },
  ],
  NY: [
    {
      internalName: 'Stop & Shop',
    },
    {
      internalName: 'Hannaford',
    },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Price Chopper',
    },
    {
      internalName: 'ShopRite Sun-Sat',
      alias: 'ShopRite',
    },
    {
      internalName: 'Wegmans',
    },
    {
      internalName: 'Walmart',
    },
  ],
  VT: [
    {
      internalName: 'Hannaford',
    },
    {
      internalName: 'Shaws and Star Market',
      alias: "Shaw's and Star Market",
    },
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Price Chopper',
    },
    {
      internalName: 'Walmart',
    },
  ],
  AL: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Winn Dixie',
    // },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Fred Meyer',
    },
    {
      internalName: 'Kroger GA, AL and SC',
      alias: 'Kroger',
    },
    {
      internalName: 'Publix Wed-Tues',
      alias: 'Publix – Sales start Wed',
    },
    {
      internalName: 'Walmart',
    },
  ],
  AK: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Fred Meyer',
    },
    {
      internalName: 'Walmart',
    },
  ],
  AZ: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Frys',
      alias: "Fry's Food",
    },
    {
      internalName: 'Smiths',
      alias: "Smith's Food and Drug",
    },
    {
      internalName: 'Walmart',
    },
    // {
    //   internalName: 'Safeway and Albertsons AZ',
    //   alias: 'Safeway and Albertsons',
    // },
  ],
  AR: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Walmart',
    },
  ],
  CA: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Safeway CA',
    //   alias: 'Safeway',
    // },
    // {
    //   internalName: 'Vons',
    // },
    {
      internalName: 'Ralphs',
    },
    // {
    //   internalName: 'Albertsons CA',
    //   alias: 'Albertsons',
    // },
    // {
    //   internalName: 'Aldi CA',
    //   alias: 'Aldi',
    // },
    // {
    //   internalName: 'Stater Bros',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  CO: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'King Soopers',
    },
    // {
    //   "internalName": "Safeway CO",
    //   "alias": "Safeway"
    // },
    {
      internalName: 'Walmart',
    },
  ],
  DE: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Giant',
    },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'ShopRite Sun-Sat',
      alias: 'ShopRite',
    },
    {
      internalName: 'Walmart',
    },
  ],
  DC: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Giant',
    },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'Walmart',
    },
  ],
  FL: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Publix Thurs-Wed',
      alias: 'Publix – Sales start Thur',
    },
    // {
    //   internalName: 'Winn Dixie',
    // },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'Walmart',
    },
  ],
  GA: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Publix Thurs-Wed',
      alias: 'Publix – Sales start Thur',
    },
    // {
    //   internalName: 'Winn Dixie',
    // },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'Kroger GA, AL and SC',
      alias: 'Kroger',
    },
    {
      internalName: 'Publix Wed-Tues',
      alias: 'Publix – Sales start Wed',
    },
    {
      internalName: 'Bi-Lo',
    },
    {
      internalName: 'Walmart',
    },
  ],
  HI: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
  ],
  ID: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Smiths',
      alias: "Smith's Food and Drug",
    },
    {
      internalName: 'Fred Meyer',
    },
    {
      internalName: 'Walmart',
    },
  ],
  IL: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Kroger Central',
    //   alias: 'Kroger',
    // },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Cub Foods',
    },
    {
      internalName: 'Hy-Vee',
    },
    // {
    //   internalName: 'Meijer',
    // },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    // {
    //   internalName: 'Jewel Osco',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  IN: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Kroger Central',
    //   alias: 'Kroger',
    // },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    // {
    //   internalName: 'Meijer',
    // },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    // {
    //   internalName: 'Jewel Osco',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  IA: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Hy-Vee',
    },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  KS: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Walmart',
    },
  ],
  KY: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    // {
    //   internalName: 'Meijer',
    // },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  LA: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Winn Dixie',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  MD: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Giant',
    },
    // {
    //   internalName: 'Giant Eagle',
    // },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'ShopRite Sun-Sat',
      alias: 'ShopRite',
    },
    {
      internalName: 'Wegmans',
    },
    {
      internalName: 'Walmart',
    },
  ],
  MI: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Kroger Michigan',
      alias: 'Kroger',
    },
    // {
    //   internalName: 'Meijer',
    // },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  MN: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Cub Foods',
    },
    {
      internalName: 'Hy-Vee',
    },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  MS: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Winn Dixie',
    // },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Walmart',
    },
  ],
  MO: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Hy-Vee',
    },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    {
      internalName: 'Walmart',
    },
    {
      internalName: 'Dierbergs',
    },
    {
      internalName: 'Schnucks',
    },
  ],
  MT: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Smiths',
      alias: "Smith's Food and Drug",
    },
    {
      internalName: 'Walmart',
    },
  ],
  NE: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Hy-Vee',
    },
    {
      internalName: 'Walmart',
    },
  ],
  NV: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Smiths',
      alias: "Smith's Food and Drug",
    },
    {
      internalName: 'Walmart',
    },
  ],
  NM: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Smiths',
      alias: "Smith's Food and Drug",
    },
    {
      internalName: 'Walmart',
    },
  ],
  NC: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Publix Thurs-Wed',
      alias: 'Publix – Sales start Thur',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'Publix Wed-Tues',
      alias: 'Publix – Sales start Wed',
    },
    {
      internalName: 'Bi-Lo',
    },
    {
      internalName: 'Wegmans',
    },
    {
      internalName: 'Walmart',
    },
  ],
  ND: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Walmart',
    },
  ],
  OH: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Kroger Ohio Cincinnati-Miami Valley',
    //   alias: 'Kroger - Cincinnati-Miami Valley',
    // },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    // {
    //   internalName: 'Giant Eagle',
    // },
    // {
    //   internalName: 'Kroger Ohio - Central',
    //   alias: 'Kroger - Columbus/Central region',
    // },
    // {
    //   internalName: 'Meijer',
    // },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  OK: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Aldi TX and OK',
    //   alias: 'Aldi',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  OR: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Fred Meyer',
    },
    // {
    //   internalName: 'Safeway and Albertsons OR',
    //   alias: 'Safeway and Albertsons',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  PA: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Giant PA',
      alias: 'Giant',
    },
    {
      internalName: 'Price Chopper',
    },
    // {
    //   internalName: 'Giant Eagle',
    // },
    {
      internalName: 'ShopRite Sun-Sat',
      alias: 'ShopRite',
    },
    {
      internalName: 'Wegmans',
    },
    {
      internalName: 'Walmart',
    },
  ],
  SC: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Publix Thurs-Wed',
      alias: 'Publix – Sales start Thur',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'Kroger GA, AL and SC',
      alias: 'Kroger',
    },
    {
      internalName: 'Publix Wed-Tues',
      alias: 'Publix – Sales start Wed',
    },
    {
      internalName: 'Bi-Lo',
    },
    {
      internalName: 'Walmart',
    },
  ],
  SD: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Aldi Sun-Sat',
      alias: 'Aldi – Sales start Sun',
    },
    {
      internalName: 'Hy-Vee',
    },
    {
      internalName: 'Walmart',
    },
  ],
  TN: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    {
      internalName: 'Publix Thurs-Wed',
      alias: 'Publix – Sales start Thur',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Publix Wed-Tues',
      alias: 'Publix – Sales start Wed',
    },
    {
      internalName: 'Walmart',
    },
  ],
  TX: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    // {
    //   internalName: 'Aldi TX and OK',
    //   alias: 'Aldi',
    // },
    // {
    //   internalName: 'Kroger Texas - Dallas',
    //   alias: 'Kroger - Dallas region',
    // },
    {
      internalName: 'HEB',
    },
    // {
    //   internalName: 'Kroger Texas - Houston',
    //   alias: 'Kroger - Houston region',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  UT: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Smiths',
      alias: "Smith's Food and Drug",
    },
    {
      internalName: 'Walmart',
    },
  ],
  VA: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Food Lion',
    },
    // {
    //   internalName: 'Kroger Mid-Atlantic',
    //   alias: 'Kroger',
    // },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Giant',
    },
    {
      internalName: 'Harris Teeter',
    },
    {
      internalName: 'Publix Wed-Tues',
      alias: 'Publix – Sales start Wed',
    },
    {
      internalName: 'Wegmans',
    },
    {
      internalName: 'Walmart',
    },
  ],
  WA: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Fred Meyer',
    },
    // {
    //   internalName: 'Safeway and Albertsons WA',
    //   alias: 'Safeway and Albertsons',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  WV: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Food Lion',
    },
    // {
    //   internalName: 'Kroger Mid-Atlantic',
    //   alias: 'Kroger',
    // },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    // {
    //   internalName: 'Giant Eagle',
    // },
    {
      internalName: 'Walmart',
    },
  ],
  WI: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Whole Foods',
    },
    {
      internalName: 'Aldi Wed-Tues',
      alias: 'Aldi – Sales start Wed',
    },
    {
      internalName: 'Hy-Vee',
    },
    // {
    //   internalName: 'Meijer',
    // },
    // {
    //   internalName: 'Fresh Thyme',
    // },
    {
      internalName: 'Pick N Save',
    },
    {
      internalName: 'Walmart',
    },
  ],
  WY: [
    {
      internalName: 'AnyStore',
      alias: 'Any Store',
    },
    {
      internalName: 'Smiths',
      alias: "Smith's Food and Drug",
    },
    {
      internalName: 'King Soopers',
    },
  ],
};
