import { html } from 'lit-element';
import '@material/mwc-button';
import '@material/mwc-icon';
import '@material/mwc-icon-button';
import '@material/mwc-radio';
import { DdCard2, CARD_BODY_ID } from './dd-card-2.js';
import { TRANSITION_PAUSE } from './dd-card-expander.js';
import './dd-shop-item-card-2.js';
import { getCategoryIconTemplate } from '../../template-helpers.js';
import '../../dd-item-editor.js';
import { getStatus } from '../../../utilities/category-status.js';
import { CATEGORY_MAP } from '../../../utilities/constants.js';
import { offsetScrollToTop, offsetScrollToBottom } from '../../../utilities/dom.js';
import { sharedStyles } from '../../../theme/shared-styles.js';
import style from './dd-shop-category-card-2.scss';
import { EVENTS } from '../../events.js';

export { SWIPE_DIRECTION, SNAP_DURATION } from '../../dd-card/dd-card.js';

/**
 * shopping list category card
 */
class DdShopCategoryCard2 extends DdCard2 {
  static get properties() {
    return {
      shoppingItems: { type: Object },
      // utilities/constants.js/CATEGORY_MAP
      categoryKey: { type: String },
      isFulfilled: { type: Boolean },
      isOpen: { type: Boolean, reflect: true },
    };
  }

  set categoryKey(value) {
    this._categoryKey = value;
    this._categoryMap = CATEGORY_MAP[value];
    this.requestUpdate();
  }

  static get styles() {
    return [sharedStyles, style];
  }

  constructor() {
    super();

    this.shoppingItems = [];
    this._categoryMap = {
      name: '',
      id: 0,
    };
  }

  firstUpdated() {
    super.firstUpdated();

    // update min view heading now that shop item cards added to DOM
    this.requestUpdate();
  }

  updated() {
    super.updated();
  }

  render() {
    const addItemHeading = `New ${this._categoryMap.name} Item`;
    const closeableItemEditor = true;
    const iconTemplate = getCategoryIconTemplate(this._categoryKey, false, true);

    return html`
      <div id="${CARD_BODY_ID}" class="dd-card-body-2">
        <dd-card-expander
          ?indentTitle=${true}
          ?isOpen=${this.isOpen}
          ?clickableTitle=${true}
          @open="${this.onExpanderOpen_}"
        >
          <div class="dd-flex-container" slot="title">
            ${iconTemplate}
            <div class="dd-left-justify-flex-fill" title="see more">
              ${this.getTitleTemplate_()}
            </div>
          </div>
          <div slot="content">
            <div id="add-item-button" class="dd-flex-start-container dd-add-button dd_show">
              <mwc-button
                icon="add"
                label="add item"
                title="add item"
                @click="${this.onAddItemClick_}"
              >
              </mwc-button>
            </div>
            <div id="item-editor-container" class="dd_hide">
              <dd-item-editor
                id="item-editor"
                .heading=${addItemHeading}
                ?closeable="${closeableItemEditor}"
                @item-editor-close="${this.onItemEditorClose_}"
                @item-editor-submit="${this.onItemEditorSubmit_}"
              >
                ${iconTemplate}
              </dd-item-editor>
            </div>
            <div>
              ${this.getShopItemTemplates_()}
            </div>
          </div>
        </dd-card-expander>
      </div>
    `;
  }

  close() {
    this.isOpen = false;
    const expander = this.shadowRoot.querySelector('dd-card-expander');
    expander.close();
  }

  getTitleTemplate_() {
    const shoppingItems = this.getActiveShopItems_();
    const name = this._categoryMap.name;

    if (shoppingItems.length > 0) {
      const uncheckedCount = getStatus(shoppingItems);
      if (uncheckedCount > 0) {
        const itemTerm = uncheckedCount === 1 ? 'item' : 'items';

        return html`
          <h5>${this._categoryMap.name}</h5>
          <span class="dd-caption">${uncheckedCount} ${itemTerm}</span>
        `;
      }

      return html`
        <span class="dd-h5">${this._categoryMap.name}</span
        ><mwc-icon class="dd-secondary-dark-fg dd-margin-left">done</mwc-icon>
      `;
    }

    return html`
      <span class="dd-h5">${name}</span>
    `;
  }

  /**
   * Activated when user selects or unselects a meal using the meal selector (checkbox) from the Shopping List
   */
  updateActive() {
    const shopItemCards = this.shadowRoot.querySelectorAll('dd-shop-item-card-2');
    shopItemCards.forEach(shopItemCard => shopItemCard.requestUpdate());
    this.requestUpdate();
  }

  maximize_(offsetNumber) {
    super.maximize_(offsetNumber);
  }

  minimize_() {
    super.minimize_();
    this._cardBody.style.left = 'initial';
  }

  onMaximizeClick_() {
    super.onMaximizeClick_();
  }

  onMinimizeClick_() {
    super.onMinimizeClick_();
  }

  // BEGIN shop item card carousel methods
  /* eslint class-methods-use-this: "off" */
  onMinimizeCarouselCard_() {}

  onMaximizeCarouselCard_(event) {
    this.hideItemEditor_();
    const shopItemCards = this.shadowRoot.querySelectorAll('dd-shop-item-card-2');
    shopItemCards.forEach(card => {
      if (!card.shoppingListItem.id || card.shoppingListItem.id !== event.detail.id) {
        card.close();
      }
    });

    if (!this.largeScreen) {
      const paddedPause = 2 * TRANSITION_PAUSE;
      offsetScrollToTop(event.target, paddedPause);
    }
  }
  // END shop item card carousel methods

  getShopItemTemplates_() {
    if (this.shoppingItems && this.shoppingItems.length > 0) {
      return this.shoppingItems.map(
        shoppingItem => html`
          <dd-shop-item-card-2
            .shoppingListItem=${shoppingItem}
            @active-changed="${this.onActiveChangedShopItem_}"
            @update="${this.onUpdateShopItem_}"
            @maximize-card="${this.onMaximizeCarouselCard_}"
            @minimize-card="${this.onMinimizeCarouselCard_}"
          >
          </dd-shop-item-card-2>
        `,
      );
    }

    return this._categoryMap.hint
      ? html`
          <div class="dd-pad-left dd-pad-right">${this._categoryMap.hint}</div>
        `
      : '';
  }

  getActiveShopItems_() {
    const self = this;
    // 'isactive' attribute not yet updated in DOM so filter by property
    const shopItemCards = Array.from(this.shadowRoot.querySelectorAll('dd-shop-item-card-2'));
    const activeShopItems = shopItemCards.filter(item => item.isActive === true);
    const shoppingItems = [];
    activeShopItems.forEach(activeShopItem => {
      const itemFromState = self.shoppingItems.find(
        shoppingItem => shoppingItem.id === activeShopItem.shoppingListItem.id,
      );
      if (itemFromState) {
        shoppingItems.push(itemFromState);
      }
    });

    return shoppingItems;
  }

  /* eslint class-methods-use-this: "off" */
  onExpanderOpen_() {
    // stub
  }

  onAddItemClick_() {
    const addItemButtonClasses = this.shadowRoot.querySelector('#add-item-button').classList;
    addItemButtonClasses.replace('dd_show', 'dd_hide');
    this.openItemEditor_();
  }

  hideItemEditor_() {
    const itemEditorClasses = this.shadowRoot.querySelector('#item-editor-container').classList;
    itemEditorClasses.replace('dd_show', 'dd_hide');

    const addItemButtonClasses = this.shadowRoot.querySelector('#add-item-button').classList;
    addItemButtonClasses.replace('dd_hide', 'dd_show');

    if (!this.largeScreen) {
      const cardBody = this.shadowRoot.querySelector(`#${CARD_BODY_ID}`);
      offsetScrollToBottom(cardBody, TRANSITION_PAUSE);
    }
  }

  openItemEditor_() {
    const itemEditorContainer = this.shadowRoot.querySelector('#item-editor-container');
    itemEditorContainer.classList.replace('dd_hide', 'dd_show');
    const itemEditor = this.shadowRoot.querySelector('#item-editor');
    itemEditor.focus();

    if (!this.largeScreen) {
      const paddedPause = 2 * TRANSITION_PAUSE;
      offsetScrollToTop(itemEditorContainer, paddedPause);
    }
  }

  onItemEditorClose_() {
    this.hideItemEditor_();
  }

  onItemEditorSubmit_(event) {
    const detail = {
      ...event.detail,
      shoppingCategory: this._categoryMap.id,
    };
    this.fireLocalEvent_(EVENTS.ADD_SHOP_ITEM_TO_CATEGORY, detail);
    this.hideItemEditor_();
  }

  onActiveChangedShopItem_() {
    this.requestUpdate();
  }

  onUpdateShopItem_(event) {
    const detail = {
      ...event.detail,
      shoppingCategory: this._categoryMap.id,
    };
    this.fireGlobalEvent_(EVENTS.UPDATE_SHOP_ITEM_FROM_CATEGORY, detail);
  }
}

customElements.define('dd-shop-category-card-2', DdShopCategoryCard2);
